<template>
  <div class="chat-container" :class="{ 'user-container': isUser }">
    <div v-if="!isUser" class="bot-circle">R</div>
    <div
      :class="[
        'chat-bubble',
        isUser
          ? 'user-message'
          : canBeCopied
          ? 'copyable-message'
          : 'bot-message',
      ]"
      style="display: flex; flex-direction: column"
    >
      <div v-if="canBeCopied">
        <span v-if="options.length === 0" class="copyable-content">
          <span>{{ text }}</span>
          <b-icon
            :title="canBeCopied ? 'Click to copy' : ''"
            icon="content-copy"
            class="copy-icon"
            @click.native="copyToClipboard(text)"
          />
        </span>
        <span v-if="options.length" class="copyable-content-multiple">
          <span>{{ text }}</span>
          <span
            v-for="(option, index) in options"
            :key="index"
            class="copyable-options"
          >
            <span>{{ option }}</span>
            <b-icon
              :title="canBeCopied ? 'Click to copy' : ''"
              icon="content-copy"
              class="copy-icon"
              @click.native="copyToClipboard(option)"
            />
          </span>
        </span>
      </div>

      <span v-else>
        {{ text }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    isUser: {
      type: Boolean,
      required: true,
    },
    canBeCopied: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  methods: {
    copyToClipboard(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Message copied to the clipboard!',
            type: 'is-info',
          });
        })
        .catch((err) => {
          console.error('Failed to copy: ', err);
        });
    },
  },
};
</script>

<style scoped>
.copyable-options {
  display: flex;
  flex-direction: row;
}

.chat-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.user-container {
  flex-direction: row-reverse; /* Align user messages to the right */
  width: 85%;
}

.bot-circle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: #5a67d8;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  border-radius: 50%;
  margin-right: 10px;
}

.user-container .bot-circle {
  margin-right: 0;
  margin-left: 10px; /* Adjust spacing for user messages */
}

.chat-bubble {
  max-width: 85%;
  padding: 10px 15px;
  border-radius: 0 15px 15px 15px;
  word-break: break-word;
  line-height: 1.5;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.chat-bubble:hover {
  background-color: #cfdcff;
}

.user-message {
  align-self: flex-end;
  background-color: #e6e8ff;
  border-radius: 15px 15px 0 15px;
  color: #333;
}

.bot-message {
  align-self: flex-start;
  background-color: #dfe9ff;
  color: #333;
}

.copyable-message {
  align-self: flex-start;
  background-color: #fff6e59b;
  border: 1px solid #ffe4b5;
  color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.copyable-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.copyable-content-multiple {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}

.copy-icon {
  margin-left: auto;
  font-size: 16px;
  color: #ffa500;
}
</style>
