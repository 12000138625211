<template>
  <b-modal
    :active="active"
    aria-role="dialog"
    aria-modal
    trap-focus
    scroll="keep"
    full-screen
    @close="closeModal"
  >
    <section class="modal-card-body">
      <template v-if="isLoading"> loading... </template>
      <template v-if="tableData && !isLoading">
        <div class="table-container" :class="previewImage && 'half-height'">
          <table>
            <thead>
              <tr>
                <th>
                  <b-checkbox
                    v-model="checkAll"
                    @input="changeHeaderCheckbox"
                  />
                </th>
                <th v-for="(header, index) in headers" class="header-cell">
                  <b-dropdown
                    :mobile-modal="false"
                    :scrollable="true"
                    :max-height="200"
                    v-model="header.option"
                    aria-role="list"
                  >
                    <template #trigger>
                      <b-button
                        :label="header.option.text"
                        type="is-primary"
                        icon-right="menu-down"
                      />
                    </template>

                    <b-dropdown-item
                      v-for="(option, index) in headerOptions"
                      :key="index"
                      :value="option"
                      aria-role="listitem"
                    >
                      <div class="media">
                        <div class="media-content">
                          <h3>{{ option.text }}</h3>
                        </div>
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, rowIndex) in tableData">
                <td>
                  <b-checkbox v-model="row.checkbox" @input="changeCheckbox" />
                </td>
                <td
                  class="table-cell"
                  v-for="(cell, cellIndex) in row.cells"
                  :contenteditable="true"
                  :class="
                    isConfidenceLow(cell.confidence)
                      ? 'red-confidence'
                      : 'green-confidence'
                  "
                  @blur="onCellLeave($event, rowIndex, cellIndex)"
                  v-text="cell.text"
                ></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="image-preview-container">
          <img :src="imageSrc" alt="Uploaded Image" class="image-preview" />
        </div>
      </template>

      <template v-else>
        <div v-if="imageSrc && !isLoading" class="cropper-container">
          <cropper :src="imageSrc" ref="cropper" />
        </div>

        <div v-if="pdfUrl" class="pdf-container">
          <canvas ref="pdfCanvas" class="pdf-canvas"></canvas>
        </div>
      </template>
    </section>

    <footer class="modal-card-foot">
      <b-button @click="closeModal" type="is-light">Cancel</b-button>
      <template v-if="pdfUrl">
        <div class="pdf-navigation">
          <b-button
            @click="previousPage"
            :disabled="currentPage === 1"
            icon-left="chevron-left"
            >Previous</b-button
          >
          <b-button @click="selectPage" type="is-primary">
            Select Page {{ currentPage }} of {{ totalPages }}
          </b-button>
          <b-button
            @click="rotateCounterClockwise"
            icon-right="file-rotate-left"
          >
          </b-button>
          <b-button @click="rotateClockwise" icon-right="file-rotate-right">
          </b-button>

          <b-button
            @click="nextPage"
            :disabled="currentPage === totalPages"
            icon-right="chevron-right"
            >Next</b-button
          >
        </div>
      </template>

      <b-button
        v-if="imageSrc && !tableData"
        @click="cropImage"
        type="is-primary"
        :loading="isLoading"
        >Crop Image</b-button
      >
      <b-button
        v-if="tableData"
        @click="addRATData"
        type="is-primary"
        :loading="isLoading"
        >Create RAT</b-button
      >
    </footer>
  </b-modal>
</template>

<script>
import { getDocument } from 'pdfjs-dist';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'pdfjs-dist/webpack.mjs';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Cropper } from 'vue-advanced-cropper';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'vue-advanced-cropper/dist/style.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import { TextractDocument } from 'amazon-textract-response-parser';
import { v4 } from 'uuid';

import client from '../../config/api';

const uuidv4 = v4;
export default {
  components: {
    Cropper,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    tool: {
      type: String,
      default: null,
    },
    file: {
      type: File,
      default: null,
    },
    isMinor: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imageSrc: null,
      croppedImage: null,
      dropFile: null,
      isLoading: false,
      tableData: null,
      headerOptions: [
        { value: 'ignore', text: 'Ignore' },
        { value: 'level', text: 'Level' },
        { value: 'assembly', text: 'Assembly' },
        { value: 'itemNumber', text: 'Item Number' },
        { value: 'partName', text: 'Part Name' },
        { value: 'partNumber', text: 'Part Number' },
        { value: 'serialNumber', text: 'Serial Number' },
        { value: 'quantity', text: 'Quantity' },
      ],
      checkAll: true,
      pdf: null,
      pdfUrl: null,
      currentPage: 1,
      totalPages: 0,
      selectedPage: null,
      previewImage: true,
      rotation: 0,
    };
  },

  computed: {
    headers() {
      const header = [];
      const maxLength = Math.max(
        ...this.tableData.map((row) => row.cells.length)
      );
      for (let index = 0; index < maxLength; index++) {
        const option = this.headerOptions[0];
        header.push({ selectedOptionIndex: index, option });
      }
      return header;
    },
    job() {
      return this.$store.state.currentJob;
    },
    isMinorComponents() {
      return this.isMinor;
    },
  },

  watch: {
    dropFile(file) {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    async file(file) {
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image'];
      if (file) {
        if (validImageTypes.includes(file.type)) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.imageSrc = e.target.result;
          };
          reader.readAsDataURL(file);
        } else if (file.type === 'application/pdf') {
          this.pdfUrl = URL.createObjectURL(file);
          this.pdf = await getDocument(this.pdfUrl).promise;
          this.totalPages = this.pdf.numPages;
          this.currentPage = 1;
          this.renderPage(this.currentPage);
        }
      }

      if (!file) {
        this.closeModal();
      }
    },
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },

  mounted() {
    window.addEventListener('resize', this.handleResize);
  },

  methods: {
    async addRATData() {
      try {
        this.isLoading = true;
        const ratRows = [];
        this.tableData.forEach((row) => {
          if (row.checkbox) {
            const newRow = this.createBlankRow();
            row.cells.forEach((cell, index) => {
              if (
                cell.text !== '' &&
                this.headers[index].option.value !== 'ignore'
              ) {
                if (
                  this.headers[index].option.value === 'level' ||
                  this.headers[index].option.value === 'quantity'
                ) {
                  newRow[this.headers[index].option.value] = parseInt(
                    cell.text,
                    10
                  );
                } else {
                  newRow[this.headers[index].option.value] = cell.text;
                }
              }
            });
            // this.headers.forEach((header) => {
            //   if (header.option.value !== 'ignore') {
            //     if (header.option.value === 'level') {
            //       newRow[header.option.value] = row.cells[
            //         header.selectedOptionIndex
            //       ]
            //         ? parseInt(row.cells[header.selectedOptionIndex].text, 10)
            //         : 1;
            //     } else {
            //       newRow[header.option.value] = row.cells[
            //         header.selectedOptionIndex
            //       ]
            //         ? row.cells[header.selectedOptionIndex].text
            //         : '';
            //     }
            //   }
            // });
            ratRows.push(newRow);
          }
        });
        await this.$emit('newRatRows', ratRows);
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
        this.closeModal();
      }
    },

    // This refreshes the page when the window is resized, such as when a table user switches from landscape to portrait.
    handleResize() {
      if (this.pdfUrl) {
        this.renderPage(this.currentPage);
      }

      if (this.imageSrc) {
        if (this.$refs.cropper && this.$refs.cropper.refresh) {
          this.$refs.cropper.refresh();
        }
      }
    },

    createBlankRow() {
      if (this.isMinorComponents) {
        return {
          id: uuidv4(),
          partName: '',
          partNumber: '',
          quantity: 1,
          reuse: 0,
          repair: 0,
          replace: 0,
          isMinor: true,
        };
      }
      return {
        id: uuidv4(),
        level: 1,
        assembly: '',
        itemNumber: '',
        partName: '',
        partNumber: '',
        serialNumber: '',
        quantity: 1,
        reuse: 0,
        repair: 0,
        replace: 0,
        missing: 0,
        clientReject: false,
        received: 0,
        reviewed: false,
        qualityControlOverride: false,
        visual: '',
        dim: '',
        ndt: '',
        summary: '',
        repairScope: '',
        estimatedLabor: '',
      };
    },

    onCellLeave(event, rowIndex, cellIndex) {
      this.tableData[rowIndex].cells[cellIndex].text = event.target.innerText;
    },

    changeHeaderCheckbox(value) {
      this.tableData.forEach((row) => {
        row.checkbox = value;
      });
    },

    changeCheckbox() {
      this.checkAll = this.tableData.every((row) => row.checkbox);
    },

    isConfidenceLow(confidence) {
      if (confidence < 85) {
        return true;
      }
      return false;
    },

    onFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },

    async cropImage() {
      if (this.$refs.cropper) {
        this.isLoading = true;
        const canvas = this.$refs.cropper.getResult({
          type: 'canvas',
          size: 'cover',
        });

        if (canvas) {
          try {
            const image = canvas.canvas.toDataURL('image/png');
            this.imageSrc = image;
            const response = await client.post('/ocr', { base64Image: image });
            const doc = new TextractDocument(response.data.result);
            const page = doc.pageNumber(1);
            // const table = page.tableAtIndex(0);
            const data = [];

            // eslint-disable-next-line no-restricted-syntax
            for (const t of page.iterTables()) {
              // eslint-disable-next-line no-restricted-syntax
              for (const row of t.iterRows()) {
                const rowData = {
                  checkbox: true,
                  cells: [],
                };
                // eslint-disable-next-line no-restricted-syntax
                for (const cell of row.iterCells()) {
                  rowData.cells.push({
                    text: cell.text !== 'NOT_SELECTED' ? cell.text : '',
                    confidence: cell.confidence,
                  });
                }
                data.push(rowData);
              }
            }

            this.tableData = data;
          } catch (error) {
            alert('OCR error, please refresh the page');
            this.closeModal();
            console.error(error);
          } finally {
            this.isLoading = false;
          }
        } else {
          this.$buefy.toast.open({
            message: 'Please crop the image before saving.',
            type: 'is-warning',
          });
        }
      }
    },
    closeModal() {
      this.dropFile = null;
      this.imageSrc = null;
      this.croppedImage = null;
      this.tableData = null;
      this.pdf = null;
      this.pdfUrl = null;
      this.currentPage = 1;
      this.totalPages = 0;
      this.selectedPage = null;
      this.rotation = 0;
      this.$emit('close');
    },

    async renderPage(pageNumber, rotation = 0) {
      const page = await this.pdf.getPage(pageNumber);

      const canvas = this.$refs.pdfCanvas;
      const context = canvas.getContext('2d');

      const container = this.$el.querySelector('.pdf-container');
      const containerWidth = container.clientWidth;
      const containerHeight = container.clientHeight;

      const viewport = page.getViewport({ scale: 1, rotation });

      const widthScale = containerWidth / viewport.width;
      const heightScale = containerHeight / viewport.height;
      const scale = Math.min(widthScale, heightScale); // Ensure it fits both width and height

      const scaledViewport = page.getViewport({ scale, rotation });

      canvas.width = scaledViewport.width;
      canvas.height = scaledViewport.height;

      canvas.style.width = `${scaledViewport.width}px`;
      canvas.style.height = `${scaledViewport.height}px`;

      const renderContext = {
        canvasContext: context,
        viewport: scaledViewport,
      };

      await page.render(renderContext).promise;
    },
    rotateClockwise() {
      this.rotation = (this.rotation + 90) % 360; // Increment rotation
      this.renderPage(this.currentPage, this.rotation);
    },
    rotateCounterClockwise() {
      this.rotation = (this.rotation - 90 + 360) % 360; // Decrement rotation
      this.renderPage(this.currentPage, this.rotation);
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.renderPage(this.currentPage);
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.renderPage(this.currentPage);
      }
    },

    async selectPage() {
      const canvas = this.$refs.pdfCanvas;
      this.imageSrc = canvas.toDataURL();
      this.pdfUrl = null;
      this.pdf = null;
      this.totalPages = 0;
      this.currentPage = 1;
    },
  },
};
</script>

<style scoped>
thead {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.table-container {
  max-height: calc(100vh - 120px); /* Set an appropriate height */
  overflow-y: auto; /* Enable scrolling */
  min-height: 300px;
}

.header-cell {
  min-width: 150px;
}

.modal-card-foot {
  justify-content: space-between;
}

.cropper-container {
  width: 100%;
  height: 100%;
}

.upload-container {
  display: flex;
  justify-content: center;
  margin: 50px 0 50px 0;
}

.table-cell {
  padding: 8px;
  text-align: left;
  box-sizing: border-box;
  border-bottom: 1px solid #ddd;
}

.table-cell:not(:last-child) {
  border-right: 1px solid #ddd;
}

.table-cell:first-child {
  width: 100px;
  text-align: center;
  overflow: hidden;
}

.red-confidence {
  background-color: rgba(255, 0, 0, 0.302);
}

.green-confidence {
  background-color: rgba(0, 128, 0, 0.228);
}

.pdf-navigation {
  display: flex;
  justify-content: space-between;
}

.pdf-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Ensure it spans the parent width */
  height: 100%; /* Ensure it spans the parent height */
  overflow: hidden; /* Prevent overflow */
  flex-direction: column;
}

.pdf-canvas {
  display: block; /* Removes extra spacing below canvas */
  max-width: 100%;
  max-height: 100%;
}

.half-height {
  height: 50%;
  box-sizing: border-box;
}

.image-preview-container {
  height: 50%;
  overflow: auto;
}

.image-preview {
  border: 1px solid #ccc;
  width: 100%;
}
</style>
