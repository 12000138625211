export default {
  SET_WEBSOCKET_STATUS: (state, status) => {
    state.websocketStatus = status;
  },

  SET_WEB_SOCKET_CONNECTION_ID: (state, connectionId) => {
    state.websocketConnectionId = connectionId;
  },

  WEBSOCKET_EVENT_ADD_SUBSCRIBER(state, { action, callback }) {
    if (!state.eventSubscribers.has(action)) {
      state.eventSubscribers.set(action, new Set());
    }
    state.eventSubscribers.get(action).add(callback);
  },

  // Removes a subscriber from the action-based subscriber list
  WEBSOCKET_EVENT_REMOVE_SUBSCRIBER(state, { action, callback }) {
    if (state.eventSubscribers.has(action)) {
      state.eventSubscribers.get(action).delete(callback);
    }
  },

  // Notifies all subscribers of a given action with the payload
  WEBSOCKET_EVENT_PUBLISH_MESSAGE(state, { action, data }) {
    if (state.eventSubscribers.has(action)) {
      state.eventSubscribers.get(action).forEach((callback) => callback(data));
    }
  },

  SET_DEBOUNCE_TIMER(state, { key, timer }) {
    state.debounceTimers.set(key, timer);
    console.log(state.debounceTimers);
  },

  REMOVE_DEBOUNCE_TIMER(state, key) {
    state.debounceTimers.delete(key);
  },
};
