<template>
  <div class="container-table" ref="content">
    <div
      class="resize-handle"
      @mousedown="startResize"
      @touchstart="startResize"
    ></div>
    <div class="table-content">
      <table v-if="tableBubblesItems.length > 0">
        <thead>
          <tr>
            <th class="table-cell">
              <b-button
                v-if="!isInspectorMode() && !isInReportMode()"
                type="is-success is-light"
                size="is-small"
                @click="addRow"
                >Add row</b-button
              >
            </th>
            <th
              v-for="(header, index) in headers"
              :key="`head-${index}`"
              class="table-cell"
              style="text-align: center"
              :contenteditable="false"
              :colspan="header.subHeaders.length"
            >
              {{ header.text }}
              <div v-if="header.description" class="table-header-description">
                {{ header.description }}
              </div>
            </th>
          </tr>
          <tr v-if="headers.some((header) => header.subHeaders.length > 1)">
            <template v-for="(header, index) in headers">
              <th v-if="index === 0" class="table-cell"></th>
              <th
                class="table-cell"
                v-for="subheader in header.subHeaders"
                v-if="header.subHeaders.length > 1"
              >
                {{ subheader }}
              </th>
              <th v-else class="table-cell"></th>
            </template>
          </tr>
        </thead>

        <tr
          v-for="(row, rowIndex) in tableData"
          :key="`row-${rowIndex}`"
          :class="{
            'odd-row': rowIndex % 2 !== 0,
            'even-row': rowIndex % 2 === 0,
          }"
        >
          <td
            class="table-cell"
            :contenteditable="!isInspectorMode() && !isInReportMode()"
            @blur="onFirstCellLeave(rowIndex, $event)"
          >
            {{ measurementsColumnNames[rowIndex] }}
          </td>

          <td
            v-for="(cell, colIndex) in row"
            :key="`cell-${rowIndex}-${colIndex}`"
            class="table-cell"
            :data-cell="`${cell.inspectionIndex}-${cell.measurementIndex}-${cell.positionIndex}`"
            :class="{
              'red-text': !areMeasurementsOk(cell, rowIndex),
            }"
            :contenteditable="isEditable(cell)"
            v-html="tableData[rowIndex][colIndex].value"
            @blur="onCellLeave(cell, $event)"
            @focus="onCellFocus(cell)"
            :tabindex="isSelectable() ? 0 : undefined"
          ></td>
        </tr>
      </table>
      <div style="display: flex; justify-content: center">
        <b-button
          type="is-danger"
          size="is-small"
          @click="removeRow"
          id="remove-row-button"
          v-if="!isInspectorMode() && !isInReportMode()"
          :expanded="false"
          >Remove Row</b-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import debounce from 'lodash/debounce';
import MobileDetect from 'mobile-detect';
import TableQuestionLayout from '../Shared/InstructionView/components/TableQuestionLayout';

import { SET_KEYPAD_NEXT_BUTTON_TABLE_BUBBLE } from '../../store/diagram/mutations/mutationsTypes';
import { GET_CURRENT_CELL_INDEX } from '../../store/diagram/getters/getterTypes';

const EMPTY_MEASUREMENT_VALUE = {
  value: '',
  images: [],
  comment: '',
};

const EMPTY_VISUAL_MEASUREMENT_VALUE = {
  value: 'notCompleted',
  images: [],
  comment: '',
};

export default {
  components: { TableQuestionLayout },
  props: ['tableBubblesItems', 'tool'],
  data() {
    return {
      isOpen: true,
      inspections: [],
      isTablet: false,
      initialHeight: 100,
      isResizing: false,
      startY: 0,
      startHeight: 0,
      clickedElement: null,
    };
  },

  watch: {
    tableBubblesItems() {
      this.inspections = this.tableBubblesItems;
    },
    cellIndexTable(payload) {
      this.focusCell(payload);
    },
  },

  mounted() {
    this.inspections = this.tableBubblesItems;
    const md = new MobileDetect(navigator.userAgent);
    this.isTablet = md.tablet() !== null;

    document.addEventListener('mousedown', this.captureClick);
  },

  beforeDestroy() {
    document.removeEventListener('mousedown', this.captureClick);
  },

  computed: {
    tableData() {
      const data = [];
      this.inspections.sort((a, b) => a.number - b.number);
      const numberOfRowsInTable = this.inspections.reduce((max, item) => {
        return Math.max(max, item.tableMeasurements.length);
      }, 0);
      for (let rowNumber = 0; rowNumber < numberOfRowsInTable; rowNumber++) {
        const col = [];
        this.inspections.forEach((inspection, inspectionIndex) => {
          if (inspection.type === 'visual') {
            let visualMeasurmeants = inspection.tableMeasurements[rowNumber];
            if (!visualMeasurmeants) {
              this.addMeasurmentRow(inspection);
              visualMeasurmeants = inspection.tableMeasurements[rowNumber];
            }

            const isAcceptable = visualMeasurmeants.every(
              (measurement) => measurement.value === ''
            );

            const isNotcomplete = visualMeasurmeants.every(
              (measurement) => measurement.value === 'notCompleted'
            );

            let colValue = isNotcomplete ? '-' : '';

            if (isAcceptable) {
              colValue = 'Acceptable';
            }

            if (!isAcceptable && !isNotcomplete) {
              if (
                visualMeasurmeants[0].value &&
                visualMeasurmeants[0].value !== 'notCompleted'
              ) {
                colValue += `Rubbed: ${visualMeasurmeants[0].value} <br>`;
              }
              if (
                visualMeasurmeants[1].value &&
                visualMeasurmeants[1].value !== 'notCompleted'
              ) {
                colValue += `Damaged: ${visualMeasurmeants[1].value} <br>`;
              }
              if (
                visualMeasurmeants[2].value &&
                visualMeasurmeants[2].value !== 'notCompleted'
              ) {
                colValue += `Pitted: ${visualMeasurmeants[2].value} <br>`;
              }
              if (
                visualMeasurmeants[3].value &&
                visualMeasurmeants[3].value !== 'notCompleted'
              ) {
                colValue += `Eroded: ${visualMeasurmeants[3].value} <br>`;
              }
            }
            col.push({
              value: colValue,
              inspectionIndex,
              measurementIndex: rowNumber,
              positionIndex: 0,
            });
          } else {
            let measurement = inspection.tableMeasurements[rowNumber];
            if (!measurement) {
              this.addMeasurmentRow(inspection);
              measurement = inspection.tableMeasurements[rowNumber];
            }

            for (let index = 0; index < inspection.measurementCount; index++) {
              let item = measurement[index];
              if (!item) {
                item = EMPTY_MEASUREMENT_VALUE;
              }
              col.push({
                value: item.value,
                inspectionIndex,
                measurementIndex: rowNumber,
                positionIndex: index,
              });
            }
          }
        });
        data.push(col);
      }
      return data;
    },

    headers() {
      const header = [];
      this.inspections.forEach((item) => {
        const subHeaders = [];
        if (item.type === 'runout') {
          if (!item.multipleMeasurements) {
            subHeaders.push('TIR');
            subHeaders.push('Position');
          } else {
            const incrementValue = Math.floor(360 / item.measurementCount);
            for (let i = 0; i < item.measurementCount; i++) {
              subHeaders.push(`${incrementValue * i} Degrees`);
            }
          }
        } else {
          for (let i = 0; i < item.measurementCount; i++) {
            subHeaders.push(`Pos: ${i + 1}`);
          }
        }

        header.push({
          text: `Label: ${item.number} (${item.type})`,
          description: item.hasDescription ? item.description : null,
          subHeaders,
        });
      });

      return header;
    },

    measurementsColumnNames() {
      const columnNames = [];

      this.inspections[0].tableMeasurements.forEach((measurement, index) => {
        if (measurement[0].tableName) {
          columnNames.push(measurement[0].tableName);
        } else {
          columnNames.push(index + 1);
        }
      });

      return columnNames;
    },

    cellIndexTable() {
      return this.$store.getters[GET_CURRENT_CELL_INDEX];
    },
  },

  methods: {
    onCellFocus(coll) {
      this.inspections.forEach((item) => {
        item.measurements = item.tableMeasurements[coll.measurementIndex];
      });
      const inspection = this.inspections[coll.inspectionIndex];

      if (!this.isInspectorMode() || this.isInReportMode()) return;
      inspection.selectedMeasurementNumber = coll.positionIndex;
      const measurements = inspection.tableMeasurements[coll.measurementIndex];
      inspection.measurements = measurements;

      let hasNextCell = true;
      let nextPositionIndex = coll.positionIndex;
      let nextInspectionIndex = coll.inspectionIndex;
      if (inspection.measurementCount > nextPositionIndex + 1) {
        nextPositionIndex += 1;
      } else if (this.inspections[nextInspectionIndex + 1]) {
        nextInspectionIndex += 1;
        nextPositionIndex = 0;
      } else {
        hasNextCell = false;
      }

      this.$store.commit(
        SET_KEYPAD_NEXT_BUTTON_TABLE_BUBBLE,
        hasNextCell
          ? {
              nextPositionIndex,
              nextInspectionIndex,
              measurementIndex: coll.measurementIndex,
            }
          : false
      );

      this.$parent.$parent.selectTableInspection(inspection);
      this.$parent.$parent.setColorsForBubblesRow(this.inspections, inspection);
    },

    onCellLeave(coll, event) {
      if (this.isInReportMode()) return;
      const inspection = this.inspections[coll.inspectionIndex];
      if (inspection.type === 'visual') {
        return;
      }
      const measurement =
        inspection.tableMeasurements[coll.measurementIndex][coll.positionIndex];
      measurement.value = event.target.innerText.trim();
      this.$parent.$parent.updateInTableMeasurment(inspection);

      if (
        this.clickedElement.parentElement.classList.contains(
          'dij-diagrambuilder-keypad-numbers'
        )
      ) {
        this.$parent.$parent.setColorsForBubblesRow(
          this.inspections,
          inspection
        );
      }
    },

    captureClick(event) {
      this.clickedElement = event.target;
      if (event.target.tagName.toLowerCase() === 'canvas') {
        document.activeElement.blur();
      }
    },

    isEditable(coll) {
      if (this.isInReportMode()) return false;
      if (this.isTablet) return false;
      const inspection = this.inspections[coll.inspectionIndex];
      return this.isInspectorMode() && inspection.type !== 'visual';
    },

    isSelectable() {
      if (this.isInReportMode()) return false;
      // const inspection = this.inspections[coll.inspectionIndex];
      return this.isInspectorMode();
    },

    onFirstCellLeave: debounce(function (rowIndex, event) {
      this.inspections.forEach((item) => {
        item.tableMeasurements[rowIndex].forEach((measurement) => {
          measurement.tableName = event.target.innerText;
        });
      });

      // Notify parent about the update
      this.$parent.$parent.updateInTableMeasurments(this.inspections);
    }, 100),

    addMeasurmentRow(inspection) {
      if (inspection.type === 'runout') {
        const numberOfMeasurements = [
          { ...EMPTY_MEASUREMENT_VALUE },
          { ...EMPTY_MEASUREMENT_VALUE },
        ];

        if (inspection.multipleMeasurements) {
          for (let i = 0; i < inspection.measurementCount; i++) {
            if (!numberOfMeasurements[i]) {
              numberOfMeasurements[i] = { ...EMPTY_MEASUREMENT_VALUE };
            }
          }
        }
        inspection.tableMeasurements.push(numberOfMeasurements);
      } else if (inspection.type === 'visual') {
        const numberOfMeasurements = [
          EMPTY_VISUAL_MEASUREMENT_VALUE,
          EMPTY_VISUAL_MEASUREMENT_VALUE,
          EMPTY_VISUAL_MEASUREMENT_VALUE,
          EMPTY_VISUAL_MEASUREMENT_VALUE,
        ];

        inspection.tableMeasurements.push(numberOfMeasurements);
      } else {
        const numberOfMeasurements = [{ ...EMPTY_MEASUREMENT_VALUE }];
        if (inspection.multipleMeasurements) {
          for (let i = 0; i < inspection.measurementCount; i++) {
            if (!numberOfMeasurements[i]) {
              numberOfMeasurements[i] = { ...EMPTY_MEASUREMENT_VALUE };
            }
          }
        }
        inspection.tableMeasurements.push(numberOfMeasurements);
      }
    },

    addRow() {
      this.inspections.forEach((item) => {
        this.addMeasurmentRow(item);
      });
      this.$parent.$parent.updateInTableMeasurments(this.inspections);
    },

    removeRow() {
      this.inspections.forEach((item) => {
        if (item.tableMeasurements.length <= 1) return;
        item.tableMeasurements.pop();
      });

      this.$parent.$parent.updateInTableMeasurments(this.inspections);
    },

    focusCell({ nextPositionIndex, nextInspectionIndex, measurementIndex }) {
      if (this.isInspectorMode()) {
        const selector = `[data-cell="${nextInspectionIndex}-${measurementIndex}-${nextPositionIndex}"]`;

        this.$nextTick(() => {
          const cell = document.querySelector(selector);
          if (cell) {
            cell.focus();
          } else {
            console.warn(`Cell with selector ${selector} not found`);
          }
        });
      }
    },

    areMeasurementsOk(cell, measurementIndex) {
      const inspection = { ...this.inspections[cell.inspectionIndex] };
      if (!inspection.tableMeasurements[measurementIndex]) {
        return true;
      }
      inspection.measurements = inspection.tableMeasurements[measurementIndex];
      let ok = true;
      if (inspection.type === 'generic') {
        ok =
          (inspection.images && inspection.images.length > 0) ||
          (inspection.comment && inspection.comment.length) > 0 ||
          (inspection.measurements[0] &&
            inspection.measurements[0].value !== '');
      } else if (inspection.type === 'visual') {
        const notFilled = inspection.measurements.every(
          (measurement) => measurement.value === ''
        );

        ok = notFilled;
      } else {
        const limits = this.getLimits(inspection);
        const lowerLimit = limits[0];
        const upperLimit = limits[1];

        let measurement = parseFloat(
          inspection.measurements[cell.positionIndex]?.value
        );
        ok = measurement >= lowerLimit && measurement <= upperLimit;

        if (inspection.type === 'runout' && !inspection.multipleMeasurements) {
          measurement = parseFloat(inspection.measurements[0]?.value);
          ok = measurement >= lowerLimit && measurement <= upperLimit;
        }
      }
      return ok;
    },

    getLimits(inspection) {
      let lowerLimit;
      let upperLimit;
      if (inspection.type === 'runout') {
        upperLimit = Math.abs(inspection.tolerance);
        lowerLimit = -upperLimit;
      } else {
        const floatExpectedValue = parseFloat(inspection.expectedValue);

        const floatNegativeTolerance =
          parseFloat(inspection.negativeTolerance) || 0;
        const floatPositiveTolerance =
          parseFloat(inspection.positiveTolerance) || 0;
        switch (inspection.toleranceType) {
          case 'normal':
            lowerLimit = floatExpectedValue - floatNegativeTolerance;
            upperLimit = floatExpectedValue + floatPositiveTolerance;

            break;
          case 'positive':
            upperLimit = Math.max(
              floatExpectedValue + floatNegativeTolerance,
              floatExpectedValue + floatPositiveTolerance
            );
            lowerLimit = Number.MIN_VALUE;
            break;
          case 'negative':
            lowerLimit = Math.min(
              floatExpectedValue + floatNegativeTolerance,
              floatExpectedValue + floatPositiveTolerance
            );
            upperLimit = Number.MAX_VALUE;
            break;
          default:
            throw new Error(
              `Failed to parse toleranceType for inspection with id ${inspection.id} and type ${inspection.type}: toleranceType ${inspection.toleranceType}`
            );
        }
      }

      let decimalPlaces = this.countDecimalPlaces(inspection.expectedValue);
      if (inspection.type === 'runout') {
        decimalPlaces = this.countDecimalPlaces(inspection.tolerance);
      }

      return [
        parseFloat(lowerLimit).toFixed(decimalPlaces),
        parseFloat(upperLimit).toFixed(decimalPlaces),
      ];
    },

    countDecimalPlaces(value) {
      if (!value) {
        return 0;
      }

      const numString = typeof value === 'number' ? value.toString() : value;

      const parts = numString.split('.');

      if (parts.length === 2) {
        return parts[1].length;
      }
      return 0;
    },

    isInspectorMode() {
      return this.tool === 'inspector';
    },

    isInReportMode() {
      return this.tool === 'report';
    },
    startResize(event) {
      this.isResizing = true;
      this.startY = event.clientY || event.touches[0].clientY;
      this.startHeight = this.$refs.content.clientHeight;
      document.addEventListener('mousemove', this.resize);
      document.addEventListener('mouseup', this.stopResize);
      document.addEventListener('touchmove', this.resize);
      document.addEventListener('touchend', this.stopResize);
    },
    resize(event) {
      if (!this.isResizing) return;
      const clientY = event.clientY || event.touches[0].clientY;
      const newHeight = this.startHeight + (this.startY - clientY);
      const maxHeight = window.innerHeight * 0.7;
      if (newHeight >= 100 && newHeight <= maxHeight) {
        this.$refs.content.style.height = `${newHeight}px`;
      }
    },
    stopResize() {
      this.isResizing = false;
      document.removeEventListener('mousemove', this.resize);
      document.removeEventListener('mouseup', this.stopResize);
      document.removeEventListener('touchmove', this.resize);
      document.removeEventListener('touchend', this.stopResize);
    },
  },
};
</script>
<style lang="scss" scoped>
.container-table {
  min-height: 100px;
  position: relative;
  background-color: white;
}

.table-content {
  overflow: scroll;
  overflow-x: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100px;
}

.table-cell {
  padding: 8px;
  text-align: left;
  box-sizing: border-box;
  min-width: 120px;
}

.table-cell:not(:last-child) {
  border-right: 1px solid #ddd;
}

.table-cell:first-child {
  width: 100px;
  text-align: center;
  overflow: hidden;
}

.odd-row {
  background-color: #ffffff;
}

.even-row {
  background-color: #dddddd48;
}

table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  border: #f5f5f5 3px solid;
  width: max-content;
}

.red-text {
  color: red;
  font-weight: bold;
}

.table-header-description {
  font-weight: normal;
  color: #645757;
}

.table-title {
  width: 120px;
}

#remove-row-button {
  margin: 20px;
}
.table-cell:focus {
  outline: 2px solid #007bff;
  border-radius: 4px;
}
.resize-handle {
  width: 100%;
  height: 10px;
  background-color: #ccc;
  cursor: ns-resize;
  position: absolute;
}
</style>
