import { GET_WEBSOCKET_CONNECTION_ID } from '../../websocket/getters/getterTypes';

export default {
  GET_TASK_QUESTIONS: (state) => {
    // Sort the questions based on the label property
    const sortedQuestions = [...state.questions].sort((a, b) => {
      return parseInt(a.label, 10) - parseInt(b.label, 10);
    });

    return sortedQuestions;
  },

  FETCH_QUESTION_LABEL: (state) => {
    // Create a set to store existing labels
    const labelSet = new Set(
      state.questions.map((question) => parseInt(question.label, 10))
    );

    // Find the smallest positive integer not used as a label
    let nextLabel = 1;
    while (labelSet.has(nextLabel)) {
      nextLabel++;
    }

    return nextLabel.toString();
  },

  IS_QUESTION_LOCKED:
    (state, getters, rootState, rootGetters) => (questionId) => {
      if (!questionId) {
        console.warn('No questionId provided to IS_QUESTION_LOCKED getter');
        return false;
      }

      const question = state.questions.find((q) => q._id === questionId);

      if (!question) {
        console.warn(`Question with id ${questionId} not found in the state.`);
        return false;
      }

      const myWsConnectionId = rootGetters[GET_WEBSOCKET_CONNECTION_ID];
      if (question.lockedByWS) {
        if (question.lockedByWS.ws === myWsConnectionId) {
          return false;
        }
      } else {
        return false;
      }

      return true;
    },
  GET_QUESTION: (state) => (questionId) => {
    if (!questionId) {
      console.warn('No questionId provided to GET_QUESTION getter');
      return false;
    }

    const question = state.questions.find((q) => q._id === questionId);

    if (!question) {
      console.warn(`Question with id ${questionId} not found in the state.`);
      return false;
    }

    return question;
  },
};
