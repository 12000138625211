var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-content",class:{ 'cal-height': _vm.displayType === 'preview' }},[(_vm.tableData)?_c('table',[_c('thead',{class:{ 'edit-mode': _vm.isEditFirstColumnEditable }},[_c('tr',[(_vm.isPartialEditMode)?_c('th',{staticClass:"table-cell",staticStyle:{"text-align":"center"},on:{"click":_vm.toggleEditMode}},[_c('b-button',{attrs:{"disabled":_vm.readOnly,"type":"is-success is-light","size":"is-small"}},[_vm._v("Edit")])],1):_vm._e(),(_vm.isDiagramEditMode)?_c('th',{staticClass:"table-cell"},[_c('b-button',{attrs:{"type":"is-success is-light","size":"is-small"}},[_vm._v("Add row")])],1):_vm._e(),_vm._l((_vm.tableData.length > 0
            ? _vm.tableData[0].slice(1)
            : []),function(header,index){return _c('th',{key:`head-${index}`,staticClass:"table-cell",staticStyle:{"text-align":"center"},attrs:{"contenteditable":_vm.isEditFirstColumnEditable},on:{"input":function($event){return _vm.handleInput($event, 0, index + 1)}}},[_vm._v(" "+_vm._s(header)+" ")])})],2)]),_c('transition-group',{attrs:{"name":"row-bounce","tag":"tbody"}},_vm._l((_vm.tableData.slice(1)),function(row,rowIndex){return _c('tr',{key:`row-${rowIndex}`,class:{
          'odd-row': rowIndex % 2 !== 0,
          'even-row': rowIndex % 2 === 0,
        }},_vm._l((row),function(cell,colIndex){return _c('td',{key:`cell-${rowIndex}-${colIndex}`,staticClass:"table-cell",class:{
            'editable-cell': _vm.isEditFirstColumnEditable && colIndex === 0,
            'editable-cell-background':
              _vm.isEditFirstColumnEditable && colIndex === 0,
          },attrs:{"contenteditable":_vm.isCellContentEditable(colIndex)},on:{"input":function($event){return _vm.handleInput($event, rowIndex + 1, colIndex)},"focus":function($event){return _vm.handleFocus($event)},"blur":_vm.onCellLeave}},[_vm._v(" "+_vm._s(cell)+" ")])}),0)}),0)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }