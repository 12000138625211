<template>
  <div class="image-container">
    <img
      :src="imageUrl"
      v-bind="$props"
      @error="errorImage"
      style="height: 300px"
      @click="openImageModal"
    />
    <div v-if="excluded" class="overlay" @click="openImageModal">
      <b-icon icon="minus-circle-outline" size="is-large" type="is-danger">
      </b-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageWithErrorFallback',
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
    excluded: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    errorImage(e) {
      e.target.src = '/img/icons/gallery_icon.png';
    },
    openImageModal() {
      this.$emit('click');
    },
  },
};
</script>

<style>
.image-container {
  position: relative;
  display: inline-block;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
