import { GET_WEBSOCKET_CONNECTION_ID } from '../../store/websocket/getters/getterTypes';

export default {
  computed: {
    webSocketConnectionId() {
      return this.$store.getters[GET_WEBSOCKET_CONNECTION_ID];
    },
  },
  methods: {
    isBubbleLocked(bubble) {
      if (bubble && bubble.lockedByWS) {
        if (bubble.lockedByWS.ws !== this.webSocketConnectionId) {
          return true;
        }
      }

      return false;
    },
    isBubbleLockedByMe(bubble) {
      if (bubble && bubble.lockedByWS) {
        if (bubble.lockedByWS.ws === this.webSocketConnectionId) {
          return true;
        }
      }

      return false;
    },
  },
};
