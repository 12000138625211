/* eslint-disable no-shadow */ /* eslint-disable eqeqeq */
<template>
  <div class="container">
    <div class="form-container">
      <b-loading
        :is-full-page="true"
        :active.sync="isLoading"
        :can-cancel="false"
      ></b-loading>
      <h4>{{ isEdit ? $t('edit') : $t('create_new_product') }}</h4>
      <md-field>
        <label>{{ $t('title') }}</label>
        <md-input required v-model="title"></md-input>
      </md-field>

      <md-field>
        <v-select
          :placeholder="$t('product_line') + ' *'"
          v-model="productLineSelected"
          class="selectProdLine"
          id="productLine"
          multiple
          value=""
          required
          :options="productsNames"
        ></v-select>
      </md-field>

      <md-field>
        <v-select
          :placeholder="$t('original_OEM') + ' *'"
          :disabled="!hasProduct"
          v-model="originalOEMBrandSelected"
          class="selectProdLine"
          id="productLine"
          multiple
          required
          value=""
          :options="originalOEMBrandNames"
        ></v-select>
      </md-field>

      <md-field>
        <v-select
          :placeholder="$t('frame_type') + ' *'"
          :disabled="!hasOEM"
          v-model="frameTypeSelected"
          class="selectProdLine"
          id="productLine"
          value=""
          multiple
          :options="frameTypeNames"
        ></v-select>
      </md-field>

      <md-field>
        <v-select
          :placeholder="$t('model') + ' *'"
          :disabled="!hasFrameType"
          v-model="modelSelected"
          class="selectProdLine"
          id="productLine"
          value=""
          multiple
          :options="modelListName"
        ></v-select>
      </md-field>

      <md-field>
        <label>{{ $t('part_number') }}</label>
        <md-input v-model="partNumber"></md-input>
      </md-field>

      <md-field>
        <label>{{ $t('document_number') }}</label>
        <md-input v-model="documentNumber"></md-input>
      </md-field>

      <md-field>
        <label>{{ $t('revision') }}</label>
        <md-input required v-model="revision"></md-input>
      </md-field>

      <md-field>
        <label>SAP Reference</label>
        <md-input v-model="sapReference"></md-input>
      </md-field>

      <section>
        <reassign-job
          v-if="isEdit && isReassignAllowed"
          :isReassignJobsActive="isEdit && isReassignActive"
          :currentOwner="currentOwner"
          @siteSelected="handleSiteSelected($event)"
          @userSelected="handleUserSelected($event)"
        ></reassign-job>
      </section>

      <div class="buttons-container">
        <button
          v-if="!isReassignActive"
          class="cancel-button"
          v-on:click="cancelForm"
        >
          {{ $t('cancel') }}
        </button>

        <button
          v-if="!isReassignActive"
          class="submit-button"
          v-on:click="handleSubmit"
          :disabled="
            title == '' ||
            revision == '' ||
            !hasProduct ||
            !hasOEM ||
            !hasFrameType ||
            !hasModel
          "
        >
          {{ $t('done') }}
        </button>
        <div class="break-rows-edit"></div>

        <div v-if="isEdit && isReassignAllowed" class="reassign-buttons-edit">
          <button
            class="editor-reassign-job-button"
            v-if="isEdit && isReassignAllowed && !isReassignActive"
            v-on:click="
              () => {
                isReassignActive = true;
              }
            "
            :disabled="!isEdit"
          >
            Reassign Job
          </button>
          <button
            class="editor-reassign-job-button-cancel"
            v-if="isReassignActive"
            @click="
              () => {
                isReassignActive = !isReassignActive;
              }
            "
            :disabled="!isEdit"
          >
            Cancel Reassign
          </button>
          <button
            class="editor-reassign-job-button-confirm"
            v-if="isReassignActive"
            @click="this.handleSubmitReassign"
            :disabled="
              !isEdit || !isSiteReassignSelected || !isUserReassignSelected
            "
          >
            Confirm Reassign
          </button>
        </div>
        <b-notification
          auto-close
          type="is-success"
          :active.sync="showSuccess"
          :duration="3000"
          aria-close-label="Close notification"
        >
          {{ successMessage }}
        </b-notification>
        <b-notification
          auto-close
          type="is-danger"
          :active.sync="showFail"
          :duration="5000"
          aria-close-label="Close notification"
        >
          {{ failedMessage }}
        </b-notification>
      </div>
    </div>
  </div>
</template>

<script>
import ReassignJob from '../layout/ReassignJob';

export default {
  components: {
    'reassign-job': ReassignJob,
  },
  name: 'EditorCreate',
  data() {
    return {
      title: '',
      partNumber: '',
      documentNumber: '',
      revision: '',
      hasProduct: false,
      hasOEM: false,
      hasFrameType: false,
      hasModel: false,
      productsNames: [],
      productLineSelected: '',
      originalOEMBrandNames: [],
      originalOEMBrandSelected: '',
      frameTypeNames: [],
      frameTypeSelected: '',
      modelListName: [],
      modelSelected: '',
      showSuccess: false,
      showFail: false,
      isLoading: false,
      isEdit: false,
      filled: false,
      filledCounter: 0,
      successMessage: this.$t('new_product_success_message'),
      failedMessage: this.$t('new_product_fail_message'),
      isReassignActive: false,
      isSiteReassignSelected: false,
      isUserReassignSelected: false,
      currentOwner: null,
      sapReference: '',
    };
  },
  watch: {
    /* Watching changes in the dropdown selection */
    /* Using data to make get requests so as
    to get data to the other dropdown below the parent one */

    async productLineSelected(newValues, oldValues) {
      if (newValues !== oldValues) {
        this.originalOEMBrandNames = [];
        this.originalOEMBrandSelected = null;
      }
      if (newValues && newValues.length !== 0) {
        this.hasProduct = true;
        const products = [];
        let product;
        newValues.forEach((item) => {
          product = this.productListName.find(
            // eslint-disable-next-line eqeqeq
            (productFind) => productFind.name == item
          );
          products.push(product._id);
        });
        // Getting data to use in the select child component
        await this.$store.dispatch('getOriginalOEMBrandList', products);

        // Filling the select components' data
        this.fillOriginalOEMSelectField();
      } else {
        this.hasProduct = false;
        this.originalOEMBrandNames = [];
        this.originalOEMBrandSelected = null;
      }
    },

    async originalOEMBrandSelected(newValues, oldValues) {
      if (newValues && newValues !== oldValues) {
        this.frameTypeNames = [];
        this.frameTypeSelected = null;
      }
      if (newValues && newValues.length !== 0) {
        this.hasOEM = true;
        const originalOEMBrands = [];
        let originalOEMBrand;
        newValues.forEach((item) => {
          originalOEMBrand = this.originalOEMBrandList.find(
            // eslint-disable-next-line eqeqeq
            (originalOEMBrandFind) => originalOEMBrandFind.name == item
          );
          originalOEMBrands.push(originalOEMBrand._id);
        });
        await this.$store.dispatch('getFrameTypeList', originalOEMBrands);
        this.fillFrameTypeSelectField();
      } else {
        this.hasOEM = false;
        this.frameTypeNames = [];
        this.frameTypeSelected = null;
      }
    },

    async frameTypeSelected(newValues, oldValues) {
      if (newValues && newValues !== oldValues) {
        this.modelsListName = [];
        this.modelSelected = null;
      }
      if (newValues && newValues.length !== 0) {
        this.hasFrameType = true;
        const frameTypes = [];
        let frameType;
        newValues.forEach((item) => {
          frameType = this.frameTypeList.find(
            // eslint-disable-next-line eqeqeq
            (frameTypeFind) => frameTypeFind.name == item
          );
          frameTypes.push(frameType._id);
        });
        await this.$store.dispatch('getModelsList', frameTypes);
        this.fillModelSelectField();
      } else {
        this.hasFrameType = false;
        this.modelListName = [];
        this.modelSelected = null;
      }
    },

    async modelSelected(selectedName) {
      if (selectedName !== null && selectedName.length !== 0) {
        this.hasModel = true;
      } else {
        this.hasModel = false;
      }
    },
  },

  computed: {
    productListName() {
      return this.$store.state.productListName;
    },
    originalOEMBrandList() {
      return this.$store.state.originalOEMBrandList;
    },
    frameTypeList() {
      return this.$store.state.frameTypeList;
    },
    modelList() {
      return this.$store.state.modelList;
    },
    newJobError() {
      return this.$store.state.jobErrors;
    },
    job() {
      return this.$store.state.currentJob;
    },

    user() {
      return this.$store.state.currentUser;
    },

    sites() {
      const { sites } = this.$store.state;
      sites.sort((a, b) => (a.siteName > b.siteName ? 1 : -1));
      return sites;
    },

    isReassignAllowed() {
      if (
        this.user.role === 'admin' &&
        (this.job.executingSite === this.user.location ||
          this.job.sites?.some((site) => site === this.user.location))
      ) {
        return true;
      }

      if (
        this.job.prodCreatedByUser._id === this.user._id ||
        (this.user.role === 'admin' && parseInt(this.user.privil, 10) === 1) ||
        this.job?.oldOwners.some((user) => user._id === this.user._id)
      ) {
        return true;
      }

      return false;
    },
  },

  async mounted() {
    // this.translateWords();

    const { id } = this.$route.params;

    if (id) {
      if (Object.keys(this.job).length === 0) {
        await this.$store.dispatch('getJob', id);
      }
      this.sapReference = this.job.sapReference;
      this.title = this.job.title;
      this.partNumber = this.job.userEnteredPartNumber;
      this.revision = this.job.revision;
      this.documentNumber = this.job.documentNumber;
      this.isEdit = true;
    }

    this.fillProductNameListSelectField();

    if (this.job?.prodCreatedByUser) {
      this.currentOwner = this.job.prodCreatedByUser;
    }
  },

  methods: {
    // translateWords(){
    //   this.successMessage =  $t('new_product_success_message');
    //   this.failMessage = $t('new_product_fail_message')
    // },

    checkFilledCounter() {
      if (this.isEdit && this.filledCounter >= 4) {
        this.filled = true;
      }
    },
    async fillProductNameListSelectField() {
      await this.$store.dispatch('getProductNameList');

      this.productListName.forEach((product) => {
        this.productsNames.push(product.name);
      });

      if (this.isEdit && !this.filled) {
        this.productLineSelected = this.job.workOrderName.map(
          (item) => item.text
        );
        this.filledCounter++;
        this.checkFilledCounter();
      }
    },

    async fillOriginalOEMSelectField() {
      this.originalOEMBrandList.forEach((oemBrand) => {
        this.originalOEMBrandNames.push(oemBrand.name);
      });
      /* If try to filter the real variable
        it doesnt filter since it's an observer array */
      /* Filtering to remove duplicates
      (so as it doesn't get error when duplicated keys)  */
      const filteredArray = [];
      this.originalOEMBrandNames.map((item) => {
        if (!filteredArray.includes(item)) {
          filteredArray.push(item);
        }
      });
      this.originalOEMBrandNames = filteredArray;
      this.originalOEMBrandNames.sort();

      if (this.isEdit && !this.filled) {
        this.originalOEMBrandSelected = this.job.brand.map((item) => item.text);
        this.filledCounter++;
        this.checkFilledCounter();
      }
    },

    async fillFrameTypeSelectField() {
      this.frameTypeList.forEach((frameType) => {
        this.frameTypeNames.push(frameType.name);
      });
      const filteredArray = [];
      this.frameTypeNames.map((item) => {
        if (!filteredArray.includes(item)) {
          filteredArray.push(item);
        }
      });
      this.frameTypeNames = filteredArray;
      this.frameTypeNames.sort();

      if (this.isEdit && !this.filled) {
        this.frameTypeSelected = this.job.frameType.map((item) => item.text);
        this.filledCounter++;
        this.checkFilledCounter();
      }
    },

    async fillModelSelectField() {
      this.modelList.forEach((model) => {
        this.modelListName.push(model.name);
      });
      const filteredArray = [];
      this.modelListName.map((item) => {
        if (!filteredArray.includes(item)) {
          filteredArray.push(item);
        }
      });
      this.modelListName = filteredArray;
      this.modelListName.sort();

      if (this.isEdit && !this.filled) {
        this.modelSelected = this.job.model.map((item) => item.text);
        this.filledCounter++;
        this.checkFilledCounter();
      }
    },

    async handleSubmit() {
      const data = {
        job: {
          workOrderName: [],
          brand: [],
          frameType: [],
          title: this.title,
          model: [],
          documentNumber: this.documentNumber,
          revision: this.revision,
          userEnteredPartNumber: this.partNumber,
          type: 'productLibrary',
          isEditable: true,
          sapReference: this.sapReference,
        },
      };
      if (!this.isEdit) data.job.date = new Date().getTime();

      this.productLineSelected.forEach((selected) => {
        const helperObject = {
          _id: '',
          text: '',
        };

        const dataChecker = this.productListName.find(
          (item) => item.name === selected
        );
        if (dataChecker !== undefined) {
          helperObject._id = dataChecker._id;
          helperObject.text = dataChecker.name;
          data.job.workOrderName.push(helperObject);
        }
      });

      this.originalOEMBrandSelected.forEach((selected) => {
        const helperObject = {
          _id: '',
          text: '',
        };

        const dataChecker = this.originalOEMBrandList.find(
          (item) => item.name === selected
        );
        if (dataChecker !== undefined) {
          helperObject._id = dataChecker._id;
          helperObject.text = dataChecker.name;
          data.job.brand.push(helperObject);
        }
      });

      this.frameTypeSelected.forEach((selected) => {
        const helperObject = {
          _id: '',
          text: '',
        };
        const dataChecker = this.frameTypeList.find(
          (item) => item.name === selected
        );
        if (dataChecker !== undefined) {
          helperObject._id = dataChecker._id;
          helperObject.text = dataChecker.name;
          data.job.frameType.push(helperObject);
        }
      });

      this.modelSelected.forEach((selected) => {
        const helperObject = {
          _id: '',
          text: '',
        };
        const dataChecker = this.modelList.find(
          (item) => item.name === selected
        );
        if (dataChecker !== undefined) {
          helperObject._id = dataChecker._id;
          helperObject.text = dataChecker.name;
          data.job.model.push(helperObject);
        }
      });

      this.isLoading = true;

      try {
        if (this.isEdit) {
          const newJob = Object.assign(this.job, data.job);
          await this.$store.dispatch('updateJob', newJob);

          this.$buefy.toast.open({
            duration: 3000,
            message: 'Product Updated Successfully!',
            type: 'is-success',
            position: 'is-top',
          });
          this.isLoading = false;
          setTimeout(
            () =>
              this.$router.push({
                name: 'editor-list',
              }),
            0
          );
          return true;
        }

        const jobId = await this.$store.dispatch('createNewJob', data);
        this.$buefy.toast.open({
          duration: 3000,
          message: 'Product Created Successfully!',
          type: 'is-success',
          position: 'is-top',
        });
        this.isLoading = false;
        setTimeout(
          () =>
            this.$router.push({
              name: 'editor-tasks',
              params: { id: jobId },
            }),
          0
        );
      } catch (error) {
        this.isLoading = false;

        this.$buefy.toast.open({
          duration: 3000,
          message:
            'Error while doing the operation to the job! Please try again.',
          type: 'is-danger',
          position: 'is-top',
        });
      }
    },

    cancelForm() {
      this.$router.push({
        name: 'editor-list',
      });
    },

    handleSiteSelected() {
      this.isSiteReassignSelected = true;
    },

    handleUserSelected(user) {
      this.isUserReassignSelected = true;
      this.userToReassign = user;
    },

    async handleSubmitReassign() {
      this.isLoading = true;
      const DTO = {
        newOwner: this.userToReassign,
        job: this.job,
      };
      try {
        await this.$store.dispatch('reassignJob', DTO);

        this.isLoading = false;
        this.showSuccess = true;
        this.successMessage = 'Job reassigned successfully!';

        setTimeout(
          () =>
            this.$router.push({
              name: 'editor-list',
            }),
          2000
        );
      } catch (err) {
        console.error('reassign job error inside create page', err);
        this.isLoading = false;

        this.showFail = true;
        this.failedMessage = 'Failed to reassign this job!';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
}
.form-container {
  margin: 8px auto;
  width: 50%;
  overflow: auto;
  padding: 10px;

  .md-field {
    font-family: 'Roboto', 'Noto', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    font-size: 20px !important;
    font-weight: 400;
    line-height: 24px;
  }

  h4 {
    font-family: 'Siemens Sans';
    color: #777;
    font-size: 24px;
  }

  [placeholder] {
    color: #777;
    font-size: 16px;
    font-family: 'Siemens Sans';
  }
}
.buttons-container {
  margin-bottom: 5%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}
.cancel-button {
  float: left;
  margin-left: 20px;
  margin: 8px;
  width: 20px;
  height: 40px;
  border-radius: 1px;
  font-size: 16px;
  min-width: 120px;
  text-transform: capitalize;
  background-color: #f5f5f5;
  color: #4a86e8;
  border: 1px solid #4a86e8;
  font-family: 'Siemens Sans';
  font-size: 16px;
}
.submit-button {
  float: left;
  margin: 8px;
  width: 20px;
  height: 40px;
  border-radius: 1px;
  font-size: 16px;
  min-width: 120px;
  text-transform: capitalize;
  background-color: #4a86e8;
  color: white;
  font-family: 'Siemens Sans';
  font-size: 16px;
  border: solid 1px rgba(255, 255, 255, 0.2);
}

button:disabled,
button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.reassign-buttons-edit {
  display: flex;
  flex-direction: row;
}

.editor-reassign-job-button {
  float: left;
  margin: 8px;
  width: 20px;
  height: 40px;
  border-radius: 1px;
  font-size: 16px;
  min-width: 120px;
  text-transform: capitalize;
  background-color: #7957d5;
  color: white;
  font-family: 'Siemens Sans';
  font-size: 16px;
  border: solid 1px rgba(255, 255, 255, 0.2);

  &:hover {
    cursor: pointer;
  }
}

.editor-reassign-job-button-cancel {
  float: left;
  margin-left: 20px;
  margin: 8px;
  width: 20px;
  height: 50px;
  border-radius: 1px;
  font-size: 16px;
  min-width: 120px;
  text-transform: capitalize;
  background-color: #f5f5f5;
  color: #4a86e8;
  border: 1px solid #4a86e8;
  font-family: 'Siemens Sans';
  font-size: 16px;

  &:hover {
    cursor: pointer;
  }
}

.editor-reassign-job-button-confirm {
  float: left;
  margin: 8px;
  width: 20px;
  height: 50px;
  border-radius: 1px;
  font-size: 16px;
  min-width: 120px;
  text-transform: capitalize;
  background-color: #48c774;
  color: white;
  font-family: 'Siemens Sans';
  font-size: 16px;
  border: solid 1px rgba(255, 255, 255, 0.2);

  &:hover {
    cursor: pointer;
  }
}

.break-rows-edit {
  flex-basis: 100%;
  height: 0;
}
</style>
