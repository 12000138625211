<template>
  <nav class="breadcrumb" aria-label="breadcrumbs">
    <ul>
      <li v-for="(item, index) in items" :key="index">
        <router-link :to="item.to" class="breadcrumb-link">
          <b-icon v-if="item.icon" :icon="item.icon" class="icon" />
          <template v-if="item.subLabels">
            {{ item.label }}
            <span v-for="(subLabel, index) of item.subLabels" :key="index">
              <span> &nbsp; / </span>{{ subLabel }}
            </span>
          </template>
          <template v-else>
            {{ item.label }}
            &nbsp;
            <span title="Number of users in task">
              ({{ numberOfWsConnectionsInTask }})
            </span>
          </template>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import { GET_CURRENT_TASK } from '../../store/tasks/getters/getterTypes';

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    currentTask() {
      return this.$store.getters[GET_CURRENT_TASK];
    },
    numberOfWsConnectionsInTask() {
      if (!this.currentTask || !this.currentTask.wsConnections) return 0;
      const uniqueWsConnections = new Set(
        this.currentTask.wsConnections.map((connection) => connection.ws)
      );

      return uniqueWsConnections.size;
    },
  },
};
</script>

<style scoped>
.breadcrumb {
  margin-left: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Media query for screens less than 1200px wide */
@media (max-width: 1600px) {
  .breadcrumb {
    max-width: 500px; /* Set maximum width to 300px */
  }
}

@media (max-width: 1300px) {
  .breadcrumb {
    max-width: 300px; /* Set maximum width to 300px */
  }
}
@media (max-width: 1100px) {
  .breadcrumb {
    max-width: 200px; /* Set maximum width to 300px */
  }
}

.icon {
  font-size: 20px;
  color: gray;
  margin-top: 0.2rem;
}

.breadcrumb ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  white-space: nowrap;
}

.breadcrumb li:not(:last-child) {
  margin-right: 0.5rem;
}

.breadcrumb li:last-child {
  font-weight: bold;
}

.breadcrumb li + li::before {
  color: #b5b5b5;
  content: '\003e';
}

.breadcrumb-link {
  font-size: 18px;
  text-decoration: none;
  color: #333;
}

.breadcrumb-link:hover {
  text-decoration: underline !important;
}
</style>
