import { v4 } from 'uuid';
import websocketService from '../../../websocketService';
import {
  WEBSOCKET_EVENT_ADD_SUBSCRIBER,
  WEBSOCKET_EVENT_PUBLISH_MESSAGE,
  WEBSOCKET_EVENT_REMOVE_SUBSCRIBER,
} from '../mutations/mutationTypes';

const uuidv4 = v4;

export default {
  async UPDATE_WEB_SOCKET_CONNECTION_ID({ commit }, connectionId) {
    commit('SET_WEB_SOCKET_CONNECTION_ID', connectionId);
  },

  async UPDATE_WEB_SOCKET_STATUS({ commit }, status) {
    commit('SET_WEBSOCKET_STATUS', status);
  },

  SEND_WEBSOCKET_MESSAGE({ commit, state }, payload) {
    const { action, data, key } = payload;

    // Validate action and data early to avoid unnecessary checks later
    if (!action) {
      console.error(
        'SEND_WEBSOCKET_MESSAGE: action parameter is missing from payload'
      );
      return;
    }

    if (!data) {
      console.error(
        'SEND_WEBSOCKET_MESSAGE: data parameter is missing from payload'
      );
      return;
    }

    let debounceDelay = 1000; // 2 seconds delay

    let messageKey = '';

    if (!key) {
      console.error(
        'SEND_WEBSOCKET_MESSAGE: key parameter is missing from payload'
      );
      debounceDelay = 0;
      messageKey = action;
    } else {
      messageKey = key + (data && data._id ? data._id : data);
    }

    console.log(
      `SEND_WEBSOCKET_MESSAGE: Received action "${action}" with key "${messageKey}".`
    );

    // Debounce logic

    // Clear the existing timer for this key, if any
    if (state.debounceTimers.has(messageKey)) {
      console.log(
        `SEND_WEBSOCKET_MESSAGE: Clearing existing debounce timer for key "${messageKey}".`
      );

      clearTimeout(state.debounceTimers.get(messageKey));
    }

    // Set a new timer for this key
    const timer = setTimeout(() => {
      console.log(
        `SEND_WEBSOCKET_MESSAGE: Timer expired for key "${messageKey}". Preparing to send message.`
      );

      try {
        const maxWaitTime = 10000; // Set a maximum wait time for connection (e.g., 10 seconds)
        let elapsedTime = 0;
        const intervalTime = 100; // Check connection status every 100ms

        const waitingInterval = setInterval(() => {
          elapsedTime += intervalTime;

          // Check WebSocket status or allow if action is 'registerToken'
          if (
            state.websocketStatus !== 'Disconnected' ||
            action === 'registerToken'
          ) {
            clearInterval(waitingInterval); // Stop checking

            try {
              // Send the WebSocket message inside a try-catch block
              console.log(`SENDING MESSAGE for action: ${action}`);

              const fifoMessage = {
                MessageGroupId: 'default',
                MessageDeduplicationId: uuidv4(),
                MessageBody: payload,
              };

              websocketService.sendMessage(fifoMessage);
            } catch (error) {
              console.error(
                `SEND_WEBSOCKET_MESSAGE: Error while sending WebSocket message for action "${action}".`,
                error
              );
            }
          } else if (elapsedTime >= maxWaitTime) {
            // Stop waiting if max time is exceeded
            clearInterval(waitingInterval);
            console.error(
              'SEND_WEBSOCKET_MESSAGE: Timed out waiting for WebSocket connection.'
            );
          } else if (elapsedTime % 1000 === 0) {
            console.log(
              `SEND_WEBSOCKET_MESSAGE: Still waiting for WebSocket connection... (${elapsedTime} ms elapsed)`
            );
          }
        }, intervalTime);
      } catch (error) {
        console.error(
          `Error while sending WebSocket message for action: ${action}`,
          error
        );
      } finally {
        console.log(
          `SEND_WEBSOCKET_MESSAGE: Timer removed for key "${messageKey}".`
        );

        // Remove the timer from the state after execution
        commit('REMOVE_DEBOUNCE_TIMER', messageKey);
      }
    }, debounceDelay);

    console.log(
      `SEND_WEBSOCKET_MESSAGE: Setting new debounce timer for key "${messageKey}" with delay ${debounceDelay} ms.`
    );

    // Save the new timer for the key in the state
    commit('SET_DEBOUNCE_TIMER', { key: messageKey, timer });
  },

  WEBSOCKET_EVENT_SUBSCRIBE_TO_ACTION({ commit }, { action, callback }) {
    console.log('Subscribing', action);
    commit(
      WEBSOCKET_EVENT_ADD_SUBSCRIBER,
      { action, callback },
      { root: true }
    );
  },

  WEBSOCKET_EVENT_UNSUBSCRIBE_FROM_ACTION({ commit }, { action, callback }) {
    console.log('Unsubscribing', action);
    commit(
      WEBSOCKET_EVENT_REMOVE_SUBSCRIBER,
      { action, callback },
      { root: true }
    );
  },

  WEBSOCKET_EVENT_HANDLE_MESSAGE({ commit }, message) {
    try {
      const { action, data } = message;

      // Publish message to all subscribers of this action
      commit(WEBSOCKET_EVENT_PUBLISH_MESSAGE, { action, data }, { root: true });
    } catch (error) {
      console.error('Failed to handle WebSocket message:', error);
    }
  },
};
