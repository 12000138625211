import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const websocketModule = {
  namespaced: true,
  state() {
    return {
      websocketStatus: 'Disconnected',
      eventSubscribers: new Map(),
      websocketConnectionId: '',
      debounceTimers: new Map(),
    };
  },
  actions,
  getters,
  mutations,
};

export default websocketModule;
