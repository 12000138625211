<template>
  <div class="header">
    <b-loading
      :is-full-page="true"
      :active.sync="isLoading"
      :can-cancel="false"
    ></b-loading>
    <div
      :hidden="this.$route.params.token || this.user._id === this.oilUser"
      class="back-button"
      @click="folderName || isTask ? gotoTaskList() : gotoProductList()"
    >
      <img src="/img/icons/back_arrow.png" />
    </div>
    <div class="breadcrumb-outer">
      <span
        :hidden="this.$route.params.token || this.user._id === this.oilUser"
        id="gotoProductList"
        @click="gotoProductList"
      >
        {{ productListText }}
      </span>
      <b-tooltip
        :label="labelTooltip"
        position="is-bottom"
        :triggers="['hover', 'click']"
        :multilined="true"
      >
        <div
          :class="'breadcrumb' + (folderName || isTask ? ' gotoTask' : '')"
          @click="task.folderId ? gotoTaskList(true) : gotoTaskList()"
        >
          <template v-if="tool.includes('editor')">
            <div class="path">
              <pre> > </pre>
              <b-tooltip
                type="is-light"
                :label="labelTooltip"
                position="is-bottom"
                class="path"
              >
                <span
                  :class="
                    this.user._id === this.oilUser
                      ? 'breadcrumb-text-external-user'
                      : 'breadcrumb-text'
                  "
                >
                  {{ labelTooltip }}
                </span>
              </b-tooltip>
            </div>
          </template>
          <template v-else>
            &nbsp;> {{ job.title }} / {{ job.customerInfoText }} /
            {{ job.projectInfoText }} / {{ job.serialNumber }} /
            {{ job.workOrderNumber }}
          </template>
        </div>
      </b-tooltip>
      <b-tooltip
        :label="folderName"
        position="is-bottom"
        :triggers="['hover', 'click']"
        v-if="folderName"
      >
        <div
          :class="
            'breadcrumb taskName' +
            (folderName && subfolderName ? ' gotoTask' : '')
          "
          v-if="folderName"
          @click="gotoTaskList(false, true)"
        >
          <div class="path">
            <pre> > </pre>
            <span>{{ folderName }}</span>
          </div>
        </div>
      </b-tooltip>
      <b-tooltip
        :label="subfolderName"
        position="is-bottom"
        :triggers="['hover', 'click']"
        v-if="subfolderName"
      >
        <div class="breadcrumb taskName" v-if="subfolderName">
          <div class="path">
            <pre> > </pre>

            <span>{{ subfolderName }}</span>
          </div>
        </div>
      </b-tooltip>
      <div v-if="isTask" class="taskName">
        <pre> > </pre>
        <b-tooltip label="Tooltip bottom" position="is-bottom">
          <span>{{ task.taskName }}</span>
        </b-tooltip>
      </div>
    </div>
    <div v-if="!readOnly" class="btn-wrapper">
      <template v-if="isTask">
        <!-- <button
          v-if="tool.includes('editor') || tool.includes('publisher')"
          :class="isOrderTasksActive ? 'btn-success' : 'btn-primary'"
          style="border-right: 1px solid;"
          v-on:click="deleteTask"
        >
          Delete
        </button>
        <button
          v-if="tool.includes('editor') || tool.includes('publisher')"
          :class="isOrderTasksActive ? 'btn-success' : 'btn-primary'"
          style="border-right: 1px solid;"
          v-on:click="deleteTask"
        >
          Save
        </button> -->
      </template>
      <template v-else>
        <button
          style="border-right: 1px solid"
          v-if="
            !isOrderTasksActive &&
            !isMultiMoveActive &&
            !tool.includes('editor')
          "
          v-on:click="showDocumentsModal"
          icon-left="folder"
        >
          <b-icon icon="file-document-arrow-right" size="is-small"> </b-icon>
          Documents
        </button>

        <button
          style="border-right: 1px solid"
          v-if="!isOrderTasksActive && !isMultiMoveActive"
          :title="
            isRepairAssessmentEnabledForCurrentLocation()
              ? ''
              : $t('feature_disabled')
          "
          :disabled="!isRepairAssessmentEnabledForCurrentLocation()"
          v-on:click="showRepairAssessment"
        >
          {{ $t('repair_assessment') }}
        </button>
        <button
          v-if="
            !tool.includes('inspector') &&
            (verifyAccess(job) || getJobId(job) === user._id) &&
            !isOrderTasksActive
          "
          :class="isMultiMoveActive ? 'btn-success' : 'btn-primary'"
          style="border-right: 1px solid"
          v-on:click="toggleMultiMove"
        >
          {{ isMultiMoveActive ? $t('select_folder') : $t('move') }}
        </button>
        <button
          v-if="
            !tool.includes('inspector') &&
            (verifyAccess(job) || getJobId(job) === user._id) &&
            !isMultiMoveActive
          "
          :class="isOrderTasksActive ? 'btn-success' : 'btn-primary'"
          style="border-right: 1px solid"
          v-on:click="toggleOrderTasks"
          :loading="isLoading"
        >
          {{ isOrderTasksActive ? $t('update') : $t('order') }}
        </button>
        <button
          v-if="displayFolderButton"
          :hidden="isOrderTasksActive || this.readOnly"
          style="border-right: 1px solid"
          class="btn-primary"
          @click="addFolder"
        >
          {{ $t('add_folder') }}
        </button>
        <button
          v-if="
            !tool.includes('inspector') &&
            (verifyAccess(job) || getJobId(job) === user._id)
          "
          :hidden="isOrderTasksActive || this.readOnly"
          class="btn-primary"
          @click="addTask"
        >
          {{ $t('add_task') }}
        </button>
        <button
          v-if="
            !folderName &&
            tool.includes('inspector') &&
            (!job.jobCompleted ||
              (job.externalClientCreator && !job.notifiedExternal))
          "
          :hidden="this.readOnly"
          class="btn-primary"
          @click="completeJob"
        >
          Complete Job
        </button>
        <button
          style="border-left: 1px solid"
          v-if="!folderName && tool.includes('publisher') && job.jobCompleted"
          :hidden="this.readOnly"
          class="btn-primary"
          @click="reopenJob"
        >
          Reopen Job
        </button>
        <template v-if="isDeletedTasksAvailable">
          <b-button
            type="is-success"
            size="is-large"
            icon-left="recycle"
            class="dij-task-recycle-bin"
            @click="showDeletedTasks"
          />
          <b-modal
            :active="isDeletedTasksVisible"
            has-modal-card
            trap-focus
            class="dij-task-deleted-modal"
            @close="hideDeletedTasks"
          >
            <div class="modal-card">
              <header class="modal-card-head">
                <b-icon icon="recycle" size="is-large" />
                <p class="modal-card-title">{{ $t('recycle_bin') }}</p>
              </header>
              <section class="modal-card-body">
                <template v-if="deletedTasks.length > 0">
                  <b-table :data="deletedTasks">
                    <template slot-scope="props">
                      <b-table-column field="taskName" :label="$t('task_name')">
                        {{ props.row.taskName }}
                      </b-table-column>
                      <b-table-column field="taskType" :label="$t('task_type')">
                        {{ props.row.taskType }}
                      </b-table-column>
                      <b-table-column
                        field="deletedBy"
                        :label="$t('deleted_by')"
                      >
                        {{ props.row.deletedBy }}
                      </b-table-column>
                      <b-table-column
                        field="deletedAt"
                        :label="$t('deleted_at')"
                      >
                        {{ convertEpochToDate(props.row.deletedAt) }}
                      </b-table-column>
                      <b-table-column :label="$t('actions')">
                        <b-tooltip label="Recover" position="is-top">
                          <b-button
                            class="dij-recycle-bin-recover"
                            type="is-primary"
                            icon-left="backup-restore"
                            @click="undelete(props.row._id)"
                          />
                        </b-tooltip>
                      </b-table-column>
                    </template>
                  </b-table>
                </template>
                <template v-else>
                  {{ $t('recycle_bin_empty') }}
                </template>
              </section>
            </div>
          </b-modal>
        </template>
      </template>
      <repair-assessment-dialog
        v-if="
          !tool.includes('inspector') &&
          isRepairAssessmentEnabledForCurrentLocation() &&
          isRepairAssessmentVisible
        "
        :read-only="
          readOnly || (!verifyAccess(job) && getJobId(job) !== user._id)
        "
        :tool="tool"
        v-on:close="hideRepairAssessment"
      />
      <repair-assessment-detail-dialog
        v-if="
          tool.includes('inspector') &&
          isRepairAssessmentEnabledForCurrentLocation() &&
          isRepairAssessmentVisible
        "
        class="dij-ra-header-modal"
        :read-only="
          readOnly || (!verifyAccess(job) && getJobId(job) !== user._id)
        "
        :tool="tool"
        v-on:close="hideRepairAssessment"
      />
      <documents-modal
        v-if="isDocumentModalVisible"
        :isDocumentModalVisible="isDocumentModalVisible"
        @close="closeDocumentModal"
      />
    </div>
  </div>
</template>

<script>
import jwt_decode from 'jwt-decode';
import RepairAssessmentDialog from './RepairAssessmentDialog';
import RepairAssessmentDetailDialog from './RepairAssessmentDetailDialog';
import FoldersManagerMixin from '../../mixins/FoldersManagerMixin';
import DocumentsModal from '../Shared/DocumentsModal';

export default {
  name: 'tasks-header',
  props: {
    isTask: {
      type: Boolean,
      default: false,
    },
    tool: String,
    limit: 10,
  },
  data() {
    return {
      isOrderTasksActive: false,
      isMultiMoveActive: false,
      isFirstClick: true,
      updateJob: false,
      notHover: true,
      showFullBreadCrumb: false,
      frameTypesNamesFormatted: [],
      labelTooltip: '',
      readOnly: false,
      oilUser: '',
      job: {},
      isRepairAssessmentVisible: false,
      isDeletedTasksVisible: false,
      isDocumentModalVisible: false,
      isLoading: false,
    };
  },
  components: {
    RepairAssessmentDialog,
    RepairAssessmentDetailDialog,
    DocumentsModal,
  },
  mixins: [FoldersManagerMixin],
  computed: {
    displayFolderButton() {
      const isEditorTool = this.tool.includes('editor');
      const isPublisherTool = this.tool.includes('publisher');
      const hasAccess = this.verifyAccess(this.job);
      const isJobOwner = this.getJobId(this.job) === this.user._id;

      return (
        (isEditorTool || isPublisherTool) &&
        (hasAccess || isJobOwner) &&
        this.respectFolderDepth
      );
    },

    productListText() {
      return this.$route.name.includes('editor')
        ? this.$t('product_template_list')
        : this.$t('work_order_list');
    },
    task() {
      return this.$store.state.task;
    },
    user() {
      return this.$store.state.currentUser;
    },
    jobsLastKey() {
      return this.$store.state.jobsLastKey;
    },
    jobTasks: {
      get() {
        return this.$store.state.currentTasks;
      },
      set(value) {
        this.$store.dispatch('updateTasksCurrentJob', value);
      },
    },
    tasks: {
      get() {
        return this.$store.state.tasks;
      },
      set(value) {
        this.$store.dispatch('setUpdatedTasks', value);
      },
    },
    sites() {
      return this.$store.state.sites;
    },
    deletedTasks() {
      return this.$store.state.deletedTasks;
    },
    isDeletedTasksAvailable() {
      return (
        !this.isTask &&
        !this.readOnly &&
        !this.job.jobCompleted &&
        (this.tool === 'editor' || this.tool === 'publisher') &&
        this.user &&
        this.user.role === 'admin'
      );
    },
  },

  async created() {
    // const { id } = this.$route.params;
    // await this.$store.dispatch('getJob', id);

    this.job = this.$store.state.currentJob;
    if (this.job) {
      this.getAndFormatFrameTypes();
      this.fillLabelTooltip();
    }
    this.$store.watch(
      () => {
        return this.$store.state.currentJob;
      },
      (currentJob) => {
        if (this.job && currentJob && this.job._id !== currentJob._id) {
          this.job = { ...currentJob };
        }

        this.getAndFormatFrameTypes();
        this.fillLabelTooltip();
      },
      {
        deep: true,
      }
    );

    this.$store.dispatch('getSites');

    if (this.isDeletedTasksAvailable) {
      const { id } = this.$route.params;
      this.$store.dispatch('getJobDeletedTasks', { jobId: id });
    }
  },
  async mounted() {
    this.oilUser = process.env.VUE_APP_OIL_USER_EMAIL;

    const { token } = this.$route.query;

    if (token) {
      try {
        this.tokenData = jwt_decode(token, process.env.JWT_SECRET);
        this.readOnly = this.tokenData.action === 'view';

        if (this.tokenData.tool !== 'inspector') {
          this.checkApprovalStatus();
        }
      } catch (err) {
        this.$router.push('/signin');
      }
    }

    if (this.tool === 'editor') {
      if (
        this.user.role !== 'admin' &&
        this.user._id !== this.job.prodCreatedByUser._id &&
        this.user._id !== process.env.VUE_APP_OIL_USER_EMAIL
      ) {
        this.readOnly = true;
      }

      return false;
    }
  },

  methods: {
    async toggleOrderTasks() {
      this.isLoading = true;
      if (this.isOrderTasksActive) {
        try {
          this.isOrderTasksActive = false;
          await this.$store.dispatch('updateTasksOrder', {
            jobId: this.job._id,
            tasks: this.tasks,
            folderId: this.folderId,
          });
          if (!this.isFirstClick) {
            this.updateJob = true;
          }
        } catch (e) {
          this.$store.dispatch('setErrorMessage', e.message);
        }
      } else {
        this.isFirstClick = false;
        this.updateJob = false;
        this.isOrderTasksActive = true;
        this.$store.dispatch('setInfoMessage', this.$t('message_order_drag'));
      }
      this.isLoading = false;
    },
    toggleMultiMove() {
      if (this.isMultiMoveActive) {
        this.isMultiMoveActive = false;
      } else {
        this.isMultiMoveActive = true;
      }
    },
    gotoProductList() {
      if (this.tool.includes('editor')) {
        this.$router.push({
          name: 'editor-list',
        });
      } else if (this.tool.includes('inspector')) {
        this.$router.push({
          name: 'inspector-list',
        });
      } else if (this.tool.includes('publisher')) {
        this.$router.push({
          name: 'publisher-list',
        });
      } else {
        this.$router.push({
          name: 'report-list',
        });
      }
    },
    gotoTaskList(task = false, folder = false) {
      if (!this.folderName && !this.isTask) {
        return false;
      }

      const { id } = this.$route.params;
      const routeName = `${this.tool}-tasks`; // e.g: 'editor-tasks'
      const { token } = this.$route.query || {};
      if (folder && !this.isTask) {
        const path = `/view/${this.tool}/${id}/folder/${this.folderId}`;
        const query = token ? { token } : undefined;
        return this.$router.push({ path, query });
      }

      if (!task && this.task.folderId) {
        const path = `/view/${this.tool}/${id}/folder/${this.task.folderId}`;
        const query = token ? { token } : undefined;
        return this.$router.push({ path, query });
      }

      if (this.subfolderId) {
        const path = `/view/${this.tool}/${id}/folder/${this.folderId}`;
        const query = token ? { token } : undefined;
        return this.$router.push({ path, query });
      }

      this.$store.commit('SET_TASK_OBJECT', {});
      const params = { id };
      const query = token ? { token } : undefined;
      this.$router.push({ name: routeName, params, query });
    },
    addFolder() {
      let path = `/view/${this.tool}/${this.job._id}/create/folder`;

      if (this.folderId) {
        path += `/${this.folderId}`;
      }

      const routeParams = {
        path,
        params: {
          id: this.job._id,
          folderId: this.subfolderId || this.folderId,
        },
      };

      if (this.$route.query.token) {
        routeParams.query = {
          token: this.$route.query.token,
        };
      }

      this.$router.push(routeParams);
    },
    addTask() {
      let routeTool = '';
      if (this.tool.includes('editor')) {
        routeTool = 'editor';
      } else if (this.tool.includes('publisher')) {
        routeTool = 'publisher';
      }

      const query = {};
      if (this.folderId) {
        query.folderId = this.folderId;
      }
      if (this.subfolderId) {
        query.folderId = this.subfolderId;
      }

      const params = {
        name: `${routeTool}-create-task`,
        params: { id: this.job._id },
        query,
      };

      if (this.$route.query.token) {
        params.query.token = this.$route.query.token;
      }

      this.$router.push(params);
    },
    getAndFormatFrameTypes() {
      if (typeof this.job.frameType === 'undefined' || !this.job.frameType)
        return '';
      if (
        this.job &&
        this.job.frameType &&
        typeof this.job.frameType !== 'string' &&
        this.job.frameType.length > 1
      ) {
        this.job.frameType.forEach((frameType, index) => {
          let formattedName;
          if (index !== 0) {
            formattedName = ` / ${frameType.text}`;
            this.frameTypesNamesFormatted.push(formattedName);
          } else {
            this.frameTypesNamesFormatted.push(frameType.text);
          }
        });
      } else if (typeof this.job.frameType === 'string') {
        this.frameTypesNamesFormatted = this.job.frameType;
      } else {
        this.frameTypesNamesFormatted = this.job.frameType[0].text;
      }
    },

    fillLabelTooltip() {
      if (!this.tool.includes('editor')) {
        this.labelTooltip = `${this.job.title} / ${this.job.customerInfoText} /
            ${this.job.projectInfoText} / ${this.job.serialNumber} /
            ${this.job.workOrderNumber}`;
      } else {
        this.labelTooltip = `${this.job.title} / ${this.frameTypesNamesFormatted} / ${this.job.userEnteredPartNumber} / ${this.job.documentNumber} / ${this.job.revision}`;
      }
    },

    checkApprovalStatus() {
      if (
        this.job?.approvalStatus === 'approved' ||
        this.job?.approvalStatus === 'requested'
      ) {
        this.readOnly = true;
      }
    },
    reopenTask(task) {
      task.workStatusCode = 1;
      this.$store.dispatch('updateTask', { task, updateState: false });
    },
    isTaskCompleted(task) {
      return task.workStatusCode
        ? task.workStatusCode.toString() === '2'
        : false;
    },
    async reopenJob() {
      const tasks = await this.$store.dispatch('getJobTasks', {
        jobId: this.job._id,
        allTasks: true,
        return: true,
      });
      // update shown tasks to visual change the progress
      this.tasks.forEach((task) => {
        if (this.isTaskCompleted(task)) this.reopenTask(task);
      });
      // update other tasks not shown
      tasks.forEach((task) => {
        if (this.isTaskCompleted(task)) this.reopenTask(task);
      });
      const newJob = { ...this.job };
      newJob.jobCompleted = false;
      newJob.jobCompleteDate = null;
      newJob.jobCompletedByUser = '';

      try {
        this.job = await this.$store.dispatch('updateJobSimple', newJob);
        this.$store.dispatch('setSuccessMessage', 'Job reopened successfuly');
      } catch (err) {
        this.$store.dispatch('setErrorMessage', 'Failed to reopen the Job');
        console.error('Failed to reopen job', err);
      }
    },
    completeJob() {
      let prompt = false;
      this.tasks.forEach((task) => {
        if (task.workStatusCode !== 2) prompt = true;
      });
      if (prompt) {
        return this.$buefy.dialog.confirm({
          message:
            'Some tasks are not yet signed off. Continue to complete them all?',
          type: 'is-success',
          hasIcon: true,
          icon: 'check',
          confirmText: 'Yes',
          onConfirm: async () => {
            return this.signOff();
          },
        });
      }
      return this.signOff();
    },
    async signOff() {
      this.$store.dispatch('setWaitingForWrite', true);
      const tasks = await this.$store.dispatch('getJobTasks', {
        jobId: this.job._id,
        allTasks: true,
        return: true,
      });

      // update shown tasks to visual change the progress
      this.tasks.forEach((task) => {
        if (task.workStatusCode !== 2) this.signOffTask(task);
      });

      // update other tasks not shown
      tasks.forEach((task) => {
        if (task.workStatusCode !== 2) this.signOffTask(task);
      });
      const newJob = { ...this.job };
      newJob.jobCompleted = true;
      newJob.jobCompleteDate = new Date();
      newJob.jobCompletedByUser = newJob.prodCreatedByUser;

      if (this.tokenData?.referral) {
        const externalClientName = this.tokenData.referral.split('_')[0];

        if (externalClientName) {
          try {
            const data = { client: externalClientName, id: this.job._id };
            await this.$store.dispatch('notifyJobCompleted', data);
            newJob.notifiedExternal = true;
            this.$store.dispatch(
              'setSuccessMessage',
              `Job completed successfuly. Notification sent to ${externalClientName}`
            );
          } catch (err) {
            this.$store.dispatch(
              'setErrorMessage',
              `Failed to send notification to ${externalClientName}`
            );
            console.error('Failed to update job', err);
            return false;
          }
        }
      }

      try {
        this.job = await this.$store.dispatch('updateJobSimple', newJob);
        this.$store.dispatch('setErrorMessage', 'Job completed successfuly');
      } catch (err) {
        this.$store.dispatch('setErrorMessage', 'Failed to complete job');
        console.error('Failed to update job', err);
      }
      this.$store.dispatch('setWaitingForWrite', false);
    },
    verifyAccess(job) {
      if (this.tool !== 'editor') {
        return true;
      }

      const isAdmin = this.user.role === 'admin';
      const isSameLocation =
        job.prodCreatedByUser &&
        (this.user.location === job.prodCreatedByUser.location ||
          (this.user.secondaryLocations &&
            this.user.secondaryLocations.some(
              (secondaryLocation) =>
                secondaryLocation === job.prodCreatedByUser.location
            )));
      const isOilUser = this.user._id === process.env.VUE_APP_OIL_USER_EMAIL;

      if (
        (isAdmin && (this.user.privil === 1 || isSameLocation)) ||
        isOilUser
      ) {
        return true;
      }

      return false;
    },
    getJobId(job) {
      if (job.prodCreatedByUser && job.prodCreatedByUser._id) {
        return job.prodCreatedByUser._id;
      }
      return '';
    },
    async signOffTask(task) {
      try {
        const taskToBeUpdated = { ...task };
        taskToBeUpdated.workStatusCode = 2;
        await this.$store.dispatch('updateTask', taskToBeUpdated);
        task.workStatusCode = 2;
      } catch (e) {
        this.$store.dispatch('setErrorMessage', e.message);
      }
    },
    showRepairAssessment() {
      this.isRepairAssessmentVisible = true;
    },
    showDocumentsModal() {
      this.isDocumentModalVisible = true;
    },
    closeDocumentModal() {
      this.isDocumentModalVisible = false;
    },
    hideRepairAssessment() {
      this.isRepairAssessmentVisible = false;
    },
    isRepairAssessmentEnabledForCurrentLocation() {
      if (!this.user || !this.sites) {
        return false;
      }
      if (this.user.privil === 1) {
        return true;
      }
      if (this.sites) {
        const currentLocation = this.sites.filter(
          (site) => site._id === this.user.location
        )[0];

        if (currentLocation) {
          return currentLocation.repairAssessmentEnabled;
        }
        return false;
      }

      return false;
    },
    showDeletedTasks() {
      this.isDeletedTasksVisible = true;
    },
    hideDeletedTasks() {
      this.isDeletedTasksVisible = false;
    },
    async undelete(taskId) {
      await this.$store.dispatch('undeleteTask', taskId);
      const { id } = this.$route.params;

      const optionsForJobTasks = { jobId: id };
      if (this.folderId) {
        optionsForJobTasks.folderId = this.folderId;
      }

      this.$store.dispatch('getJobTasks', optionsForJobTasks);
      this.$store.dispatch('getJobDeletedTasks', { jobId: id });
    },
    convertEpochToDate(epoch) {
      const date = new Date();
      date.setTime(epoch * 1000);
      return date
        .toISOString()
        .slice(0, 19)
        .replace(/-/g, '/')
        .replace('T', ' ');
    },
  },
};
</script>
<style lang="scss" scoped>
.b-tooltips {
  .b-tooltip:not(:last-child) {
    margin-right: 0.5em;
  }
  .b-tooltip {
    margin-bottom: 0.5em;
  }
}
.btn-wrapper {
  display: inline-flex;
}
pre {
  margin: 0;
  padding: 0;
  background: none;
}
.header {
  font-family: 'Siemens Sans';
  display: inline-flex;
  position: relative;
  width: 100%;
  height: 50px;
  background-color: #fafafa;
  font-size: 18px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
  color: #333;
  .back-button {
    display: inline-flex;
    width: 80px;
    height: 100%;
    cursor: pointer;
    img {
      width: 25px;
      margin: auto;
      &:hover {
        filter: brightness(0%);
      }
    }
  }

  .breadcrumb-outer {
    flex: 1 1 auto;
    display: inline-flex;
  }

  #gotoProductList,
  .breadcrumb {
    margin: auto 0;
    overflow: hidden;
    text-overflow: ellipsis;
    .breadcrumb-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .breadcrumb-text-external-user {
      max-width: 600px;
      margin-left: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  #gotoProductList {
    color: #ccc;
    &:hover {
      color: inherit;
      cursor: pointer;
    }
  }

  .path {
    display: inline-flex;
  }
}
.gotoTask {
  cursor: pointer;
  &:hover {
    color: gray;
  }
}
.taskName {
  margin: auto 0;
  width: fit-content;
  display: inline-flex;
}

.dij-task-recycle-bin.button.is-success {
  border-left: 1px solid;
  height: 50px;
  padding-top: 0;
  padding-bottom: 0;
}

.dij-task-deleted-modal .modal-card {
  width: 960px;

  .modal-card-body {
    font-size: 16px;
  }
}

/* Tablet styles */
@media only screen and (max-width: 1024px) {
  .breadcrumb {
    width: 150px; /* Adjusted width for tablets */
  }
}
</style>
