/** General */
import Vue from 'vue';
import VueCookies from 'vue-cookies';

/** Styles */
import VueMaterial from 'vue-material';
import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default.css';
import './assets/main.scss';

import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

import VueExcelEditor from 'vue-excel-editor';
import VueI18n from 'vue-i18n';

import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

import '@mdi/font/css/materialdesignicons.css';
import Buefy from 'buefy';

// eslint-disable-next-line import/no-extraneous-dependencies
import VueNativeSock from 'vue-native-websocket';
import App from './App';

import store from './store';
import router from './router';
import 'buefy/dist/buefy.css';

import ChatBoxPlugin from './plugins/chatbox';

import messages from '../public/lang';
import websocketService from './websocketService';

Vue.use(ChatBoxPlugin);

Vue.use(Buefy);
Vue.use(VueMaterial);
Vue.use(VueExcelEditor);
Vue.use(VueI18n);
Vue.use(VueCookies);
Vue.component('v-select', vSelect);
Vue.config.productionTip = false;

// Configure vue-native-websocket
Vue.use(VueNativeSock, process.env.VUE_APP_WEBSOCKET_CONNECTION_STRING, {
  connectManually: false,
  reconnection: true,
  reconnectionAttempts: 20,
  reconnectionDelay: 3000,
  format: 'json',
});

function checkAndSetDefaultLanguage() {
  let defaultLanguage = Vue.$cookies.get('lang');

  if (!defaultLanguage) defaultLanguage = 'en';
  Vue.$cookies.config(new Date(2050, 12).toUTCString(), '/');
  Vue.$cookies.set('lang', defaultLanguage);

  const i18n = new VueI18n({
    locale: defaultLanguage, // set locale
    fallbackLocale: 'en',
    messages, // set locale messages
  });

  return i18n;
}

Sentry.init({
  Vue,
  dsn: 'https://f04b2144d266448c9326ffbbf72cc71f@crash.siemens-energy.com/45',
  environment: process.env.VUE_APP_NODE_ENV,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      logErrors: true,
    }),
  ],
  tracesSampleRate: 0.2,
  ignoreErrors: ['Navigation cancelled from', 'Avoided redundant navigation'],
});
Sentry.configureScope((scope) => {
  scope.setExtra(store.state);
});

const i18n = checkAndSetDefaultLanguage();

new Vue({
  render: (h) => h(App),
  router,
  store,
  i18n,
  sockets: {
    onopen: websocketService.onOpen,
    onclose: websocketService.onClose,
    onerror: websocketService.onError,
    onmessage: websocketService.onMessage,
  },
}).$mount('#app');
