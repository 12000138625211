<template>
  <InstructionViewPublisher
    v-if="isEditorOrPublisher"
    ref="instructionViewPublisher"
    :task="task"
  />
  <InstructionViewInspector
    v-else
    ref="instructionViewInspector"
    :task="task"
  />
</template>

<script>
import InstructionViewPublisher from './InstructionViewPublisher';
import InstructionViewInspector from './InstructionViewInspector';
import {
  WEBSOCKET_EVENT_SUBSCRIBE_TO_ACTION,
  WEBSOCKET_EVENT_UNSUBSCRIBE_FROM_ACTION,
} from '../../../store/websocket/actions/actionTypes';
import {
  ADD_QUESTION,
  MUTATION_UPDATE_QUESTION,
  MUTATION_UPDATE_QUESTIONS,
  QUESTIONS_ORDER,
  REMOVE_QUESTION,
} from '../../../store/questions/mutations/mutationTypes';
import { GET_QUESTION } from '../../../store/questions/getters/getterTypes';
import { GET_WEBSOCKET_CONNECTION_ID } from '../../../store/websocket/getters/getterTypes';

export default {
  components: {
    InstructionViewPublisher,
    InstructionViewInspector,
  },
  computed: {
    isEditorOrPublisher() {
      return this.viewType === 'publisher' || this.viewType === 'editor';
    },
    wsConnectionId() {
      return this.$store.getters[GET_WEBSOCKET_CONNECTION_ID];
    },
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
    viewType: {
      type: String,
      required: true,
    },
  },
  async created() {
    const wsMappings = {
      questionCreated: this.wsQuestionCreated,
      questionDeleted: this.wsQuestionDeleted,
      questionUpdated: this.wsQuestionUpdated,
      questionsUpdated: this.wsQuestionsUpdated,
      questionLocked: this.wsQuestionLocked,
      questionUnlocked: this.wsQuestionUnlocked,
    };

    Object.keys(wsMappings).forEach(async (key) => {
      await this.$store.dispatch(WEBSOCKET_EVENT_SUBSCRIBE_TO_ACTION, {
        action: key,
        callback: wsMappings[key],
      });
    });
  },

  beforeDestroy() {
    const wsMappings = {
      questionCreated: this.wsQuestionCreated,
      questionDeleted: this.wsQuestionDeleted,
      questionUpdated: this.wsQuestionUpdated,
      questionsUpdated: this.wsQuestionsUpdated,
      questionLocked: this.wsQuestionLocked,
      questionUnlocked: this.wsQuestionUnlocked,
    };

    Object.keys(wsMappings).forEach(async (key) => {
      await this.$store.dispatch(WEBSOCKET_EVENT_UNSUBSCRIBE_FROM_ACTION, {
        action: key,
        callback: wsMappings[key],
      });
    });
  },
  methods: {
    wsQuestionLocked(data) {
      if (
        this.$refs.instructionViewPublisher &&
        this.$refs.instructionViewPublisher.selectedInstruction &&
        this.$refs.instructionViewPublisher.selectedInstruction._id ===
          data._id &&
        data.lockedByWS.ws !== this.wsConnectionId
      ) {
        this.$buefy.toast.open({
          duration: 7000,
          message: `${data.lockedByWS.fullname} obtained the lock on instruction ${this.$refs.instructionViewPublisher.selectedInstruction.label}, just before your action.`,
          type: 'is-success',
          position: 'is-bottom-left',
        });
        this.$refs.instructionViewPublisher.onItemSelectionClose();
      }

      this.wsQuestionUpdated(data);
    },
    wsQuestionUnlocked(data) {
      this.wsQuestionUpdated(data);
    },
    closeDialogOnWsEvent(data, callback) {
      const oldQuestion = this.$store.getters[GET_QUESTION](data._id);
      if (oldQuestion.lockedByWS && !data.lockedByWS) {
        if (
          this.$refs.instructionViewPublisher &&
          this.$refs.instructionViewPublisher.selectedInstruction &&
          this.$refs.instructionViewPublisher.selectedInstruction._id ===
            data._id
        ) {
          this.$refs.instructionViewPublisher.onItemSelectionClose();
          if (callback) {
            callback(true, oldQuestion.lockedByWS.fullname);
          }
        }

        if (
          this.$refs.instructionViewInspector &&
          data._id === this.$refs.instructionViewInspector.selectedQuestion._id
        ) {
          if (this.$refs.instructionViewInspector.isGalleryDisplayed) {
            this.$refs.instructionViewInspector.hideGallery();
            this.$buefy.toast.open({
              duration: 7000,
              message: `${oldQuestion.lockedByWS.fullname} has updated or deleted the instruction. You were working on number "${oldQuestion.label}". Please reopen!`,
              type: 'is-warning',
              position: 'is-bottom-left',
            });
          }
        }
      }
    },
    wsQuestionsUpdated(data) {
      this.$store.commit(MUTATION_UPDATE_QUESTIONS, data);
    },
    wsQuestionUpdated(data) {
      const notificationAfterUnlock = (unlocked, unlockedBy) => {
        if (unlocked) {
          this.$buefy.toast.open({
            duration: 7000,
            message: `${unlockedBy} unlocked the instruction ${data.label}, it is now free to edit.`,
            type: 'is-success',
            position: 'is-bottom-left',
          });
        }
      };
      this.closeDialogOnWsEvent(data, notificationAfterUnlock);

      this.$store.commit(MUTATION_UPDATE_QUESTION, data);
    },
    wsQuestionDeleted(data) {
      this.closeDialogOnWsEvent(data);

      this.$store.commit(REMOVE_QUESTION, data._id);
      this.$store.commit(QUESTIONS_ORDER);
    },
    wsQuestionCreated(data) {
      this.$store.commit(ADD_QUESTION, data);
    },
    onSave() {
      if (this.isEditorOrPublisher) {
        this.$refs.instructionViewPublisher.forceSave();
      } else {
        this.$refs.instructionViewInspector.forceSave();
      }
    },
  },
};
</script>
