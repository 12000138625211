<template>
  <b-carousel
    :autoplay="false"
    indicator-custom
    :indicator-inside="false"
    :overlay="true"
    :key="renderKey"
    v-model="carouselIndex"
    @change="info($event)"
  >
    <b-carousel-item
      v-for="(image, index) in images"
      :key="getKeyImage(image, index)"
    >
      <div class="dij-gallery-image-block">
        <div style="position: relative; margin-top: -80px">
          <img :class="getImageClass(image)" :src="getImageUrl(image)" />
          <div
            v-if="getImageNameText(index) || getImageDescription(index)"
            class="image-info"
          >
            <div class="image-info-style image-name">
              <strong v-if="getImageNameText(index)"
                >{{ getImageNameText(index) }} :</strong
              >
              {{
                getImageDescription(index) ? getImageDescription(index) : '-'
              }}
            </div>
          </div>
        </div>
      </div>
    </b-carousel-item>
    <span @click="close()" class="modal-close is-large" />

    <template #indicators="props">
      <div class="footer-image">
        <figure class="al image" :draggable="false">
          <img :draggable="false" :src="getPropImage(props.i)" />
          <div class="dij-gallery-delete">
            <b-button
              icon-left="download"
              type="is-info"
              @click="downloadImage(props.i)"
              class="top-margin"
            />
            <b-button
              icon-left="pencil"
              type="is-success"
              :disabled="readOnly"
              @click="editDescription(props.i)"
              class="top-margin"
            />

            <b-button
              v-if="!reportMode"
              icon-left="delete"
              type="is-danger"
              :disabled="readOnly"
              @click="deleteImage(props.i)"
              class="top-margin"
            />

            <b-button
              v-if="reportMode && !isImageExcludeWrapper(props.i)"
              icon-left="minus-circle-outline"
              type="is-danger"
              :disabled="readOnly"
              class="top-margin"
              @click="onImageToExcludeWrapper(props.i, true)"
            />

            <b-button
              v-if="reportMode && isImageExcludeWrapper(props.i)"
              icon-left="plus-circle-outline"
              type="is-success is-light"
              :disabled="readOnly"
              class="top-margin"
              @click="onImageToExcludeWrapper(props.i, false)"
            />
          </div>
        </figure>
      </div>
    </template>
  </b-carousel>
</template>
<script>
import axios from 'axios';
import ExcludeItemsMixin from '../../mixins/Report/ExcludeItemsMixin';
import { REPORTS_TOGGLE_EXCLUDE_ITEMS } from '../../store/reports/actions/actionTypes';

export default {
  name: 'image-gallery',
  props: ['value', 'readOnly', 'type', 'startIndex', 'reportMode'],
  emits: ['close', 'input', 'onEditInformation', 'onDeleteImage'],
  mixins: [ExcludeItemsMixin],

  data() {
    return {
      tempImageData: {
        name: '',
        description: '',
      },
      renderKey: 0,
      hasChanged: false,
      carouselIndex: 0,
    };
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeydown);
    if (this.startIndex) {
      this.carouselIndex = this.startIndex;
    }
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeydown);
  },

  computed: {
    images: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    info(value) {
      this.carouselIndex = value;
      this.scrollToIndicator();
    },

    getKeyImage(image, index) {
      const url = image.url || image.src || image.path;
      return url + index;
    },

    handleKeydown(event) {
      if (event.key === 'ArrowRight') {
        this.nextImage();
      } else if (event.key === 'ArrowLeft') {
        this.prevImage();
      } else if (event.key === 'Escape') {
        this.close();
      }
    },
    nextImage() {
      if (this.carouselIndex < this.images.length - 1) {
        this.carouselIndex++;
      } else {
        this.carouselIndex = 0; // Loop back to the first image
      }
      this.scrollToIndicator();
    },
    prevImage() {
      if (this.carouselIndex > 0) {
        this.carouselIndex--;
      } else {
        this.carouselIndex = this.images.length - 1; // Loop back to the last image
      }
      this.scrollToIndicator();
    },
    scrollToIndicator() {
      this.$nextTick(() => {
        const indicators = this.$el.querySelectorAll('.indicator-item');
        if (indicators[this.carouselIndex]) {
          indicators[this.carouselIndex].scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'center',
          });
        }
      });
    },
    close() {
      this.$emit('close', this.hasChanged);
    },
    getImageName(index) {
      const imageUrl = this.getPropImage(index);
      return imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
    },
    downloadImage(index) {
      const imageUrl = this.getPropImage(index);

      axios({
        url: imageUrl,
        method: 'GET',
        responseType: 'blob',
      })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: response.headers['content-type'],
          });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          const filename = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
          link.setAttribute('download', filename);
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((error) => {
          console.error('Error downloading image:', error);
        });
    },
    getImageDescription(index) {
      return this.images[index].description;
    },
    getImageNameText(index) {
      return this.images[index].name;
    },

    isImageExcludeWrapper(index) {
      const image = this.images[index];
      return this.isImageExclude(image.src || image.url || image.path);
    },

    onImageToExcludeWrapper(index, value) {
      const image = this.images[index];
      this.onImageToExclude(value, image.src || image.url || image.path);
      this.$store.dispatch(REPORTS_TOGGLE_EXCLUDE_ITEMS);
      this.$store.dispatch(REPORTS_TOGGLE_EXCLUDE_ITEMS);
    },

    getPropImage(value) {
      return this.getImageUrl(this.images[value]);
    },
    getImageUrl(image) {
      const url = image.url || image.path || image.src || undefined;

      // For local env we need to point to the local s3
      if (window.location.host.includes('localhost')) {
        return `http://localhost:4566/images${url}`;
      }

      return url;
    },
    editDescription(index) {
      // Initialize tempImageData from the selected image
      const { name, description } = this.images[index];
      this.tempImageData = { name, description };

      // Open the Buefy dialog
      this.$buefy.dialog.confirm({
        title: 'Image information',
        type: 'is-success',
        confirmText: 'Confirm',
        cancelText: 'Cancel',
        message: `
          <div style="display: flex; flex-direction: column; gap: 15px;">
            <div style="display: flex; flex-direction: column; gap: 5px;">
              <label for="dialogNameInput" style="font-weight: bold;">Name:</label>
              <input id="dialogNameInput" type="text" value="${this.tempImageData.name}"
                    style="padding: 8px; font-size: 14px; border-radius: 5px; border: 1px solid #ccc;">
            </div>
            <div style="display: flex; flex-direction: column; gap: 5px;">
              <label for="dialogDescriptionInput" style="font-weight: bold;">Description:</label>
              <input id="dialogDescriptionInput" type="text" value="${this.tempImageData.description}"
                    style="padding: 8px; font-size: 14px; border-radius: 5px; border: 1px solid #ccc;">
            </div>
          </div>
        `,
        onConfirm: () => {
          // Retrieve and update the input values
          this.images[index].name =
            document.getElementById('dialogNameInput').value;

          this.images[index].description = document.getElementById(
            'dialogDescriptionInput'
          ).value;

          // Show success message and emit an event
          this.$buefy.toast.open('Updated image information');
          this.$emit('onEditInformation', {
            index,
            name: this.images[index].name,
            description: this.images[index].description,
          });
        },
      });
    },
    deleteImage(index) {
      this.hasChanged = true;

      this.images.splice(index, 1);

      if (this.value.length === 0) {
        this.close();
      } else {
        // Force the component to rerender
        this.renderKey++;
      }
      this.$emit('onDeleteImage', index);
    },
    getImageClass(image) {
      let imageClass;
      if (image.width > image.height) {
        imageClass = 'dij-gallery-image-width';
      } else {
        imageClass = 'dij-gallery-image-height';
      }

      return imageClass;
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  object-fit: contain;
}
.footer-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.dij-gallery-image-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;

  .dij-gallery-image-width {
    width: 100%;
    height: 70vh;
  }

  .dij-gallery-image-height {
    height: 68vh;
  }
}

a.is-active .al img {
  border: 1px solid #fff;
  filter: grayscale(0%);
}

.al {
  display: flex;
  justify-content: center;
  img {
    border: 1px solid transparent;
    filter: grayscale(100%);
    height: 20vh;
    width: auto;
  }
}

a.is-active .al .dij-gallery-delete button {
  display: block;
}

.dij-gallery-delete {
  position: absolute;
  display: flex;
  align-items: end;
  gap: 10px;
  justify-content: center;
  width: 100%;
  height: 100%;

  button {
    display: none;
  }
}

.image-info {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: absolute;
  bottom: 0;
  color: black;
}

.image-info-style {
  display: flex;
  gap: 10px;
  align-items: center;
  background-color: #ffffffb0;
  padding: 5px;
  border-radius: 3px;
}

.top-margin {
  margin-bottom: 10px;
}
</style>
