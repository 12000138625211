const mutations = {
  SET_KEYPAD_NEXT_BUTTON_TABLE_BUBBLE: (state, value) => {
    state.keypadNextButtonTableBubble = value;
  },

  NEXT_CELL_BUBBLE_TABLE: (state, payload) => {
    state.nextTableBubbleCellIndex = payload;
  },
};

export default mutations;
