<template>
  <VueDraggableResizable
    v-if="isVisible"
    :w="400"
    :h="600"
    :x="this.position.x"
    :y="this.position.y"
    :draggable="isDragging"
    style="position: absolute; z-index: 9999"
  >
    <transition name="fade">
      <div class="dialog-container">
        <div
          class="dialog"
          :style="{ top: `${position.y}px`, left: `${position.x}px` }"
        >
          <ChatHeader
            :title="'Ralph-i'"
            @close="closeChatBox"
            @mousedown.native="startDrag"
            @mouseup.native="stopDrag"
          />
          <ChatBody
            :messages="messages"
            :style="{ opacity: showLoading ? '0.3' : '1' }"
          />

          <SpinningWheel v-if="showLoading" />
          <ChatFooter @send="addMessage" />
        </div>
      </div>
    </transition>
  </VueDraggableResizable>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import VueDraggableResizable from 'vue-draggable-resizable';
// eslint-disable-next-line import/no-unresolved

import ChatHeader from './components/ChatHeader';
import ChatBody from './components/ChatBody';
import ChatFooter from './components/ChatFooter';
import LoadingBar from './components/LoadingBar';
import SpinningWheel from './components/SpinningWheel';

export default {
  components: {
    ChatHeader,
    ChatBody,
    ChatFooter,
    LoadingBar,
    VueDraggableResizable,
    SpinningWheel,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Chat Box',
    },
    message: {
      type: String,
      default: 'How can I help you?',
    },
  },
  data() {
    return {
      isDragging: false,
      position: { x: window.innerWidth - 520, y: window.innerHeight - 720 },
      offset: { x: 0, y: 0 },
      messages: [],
      showLoading: true,
    };
  },
  watch: {
    isVisible(val) {
      if (val) {
        this.initializeDialogPosition();
      }
    },
    message(newMessage) {
      this.messages = [];
      this.messages.push({ text: newMessage, isUser: false });
      this.showLoading = true;
      setTimeout(() => {
        this.messages.push({
          text: 'This message can be copied into the clipboard!',
          isUser: false,
          canBeCopied: true,
        });

        this.messages.push({
          text: 'This message contain options',
          isUser: false,
          canBeCopied: true,
          options: ['Option 1', 'Option 2', 'Option 3'],
        });

        this.showLoading = false;
      }, 1000);
    },
  },
  mounted() {
    // Select all elements with the 'handle' class and set opacity to 0
    this.$nextTick(() => {
      const handles = document.querySelectorAll('.handle');
      handles.forEach((handle) => {
        handle.style.opacity = '0';
        handle.style.width = '25px';
        handle.style.height = '25px';
      });
    });
  },
  methods: {
    onCompleted() {
      this.showLoading = false;
    },
    closeChatBox() {
      this.$emit('close');
    },
    initializeDialogPosition() {
      this.position.x = window.innerWidth - 520;
      this.position.y = window.innerHeight - 720;
    },
    startDrag(event) {
      console.log('startDrag');
      const dragIcon = event.target.closest('.drag-icon');
      if (dragIcon) {
        this.isDragging = true;
      }
    },
    stopDrag() {
      this.isDragging = false;
    },
    addMessage(newMessage) {
      this.messages.push({ text: newMessage, isUser: true });
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from {
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

.fade-leave-from {
  opacity: 1;
}

.fade-leave-to {
  opacity: 0;
}

.dialog-container {
  width: 100%;
  height: 100%;
}

.dialog {
  width: 100%;
  height: 100%;
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: 1000; /* Ensures it's on top of other elements */
  pointer-events: auto; /* Allows interaction with the dialog */
}
</style>
