<template>
  <div class="chat-content" ref="chatContent">
    <transition-group name="fade" tag="div">
      <div
        v-for="(msg, index) in messages"
        :key="msg.id || index"
        :class="['chat-message-wrapper', msg.isUser ? 'user' : 'bot']"
      >
        <ChatMessage
          :text="msg.text"
          :isUser="msg.isUser"
          :canBeCopied="msg.canBeCopied"
          :options="msg.options"
        />
      </div>
    </transition-group>
  </div>
</template>

<script>
import ChatMessage from './ChatMessage';

export default {
  components: {
    ChatMessage,
  },
  props: {
    messages: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.scrollToBottom();
  },
  updated() {
    this.scrollToBottom();
  },
  methods: {
    scrollToBottom() {
      const { scrollHeight, clientHeight } = this.$refs.chatContent;
      this.$refs.chatContent.scrollTop = scrollHeight - clientHeight;
    },
  },
};
</script>

<style scoped>
.chat-content {
  padding: 5px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.chat-content::-webkit-scrollbar {
  width: 8px;
}

.chat-content::-webkit-scrollbar-thumb {
  background: #5b3ea5;
  border-radius: 5px;
}

.chat-content::-webkit-scrollbar-track {
  background: #ececec;
}

.chat-message-wrapper {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
}

.chat-message-wrapper:last-child {
  margin-bottom: 0;
}

.chat-message-wrapper.user {
  justify-content: flex-end;
}

.chat-message-wrapper.bot {
  justify-content: flex-start;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.fade-enter-from {
  opacity: 0;
  transform: translateY(10px);
}

.fade-enter-to {
  opacity: 1;
  transform: translateY(0);
}

.fade-leave-from {
  opacity: 1;
  transform: translateY(0);
}

.fade-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
</style>
