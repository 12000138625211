<template>
  <div>
    <TaskHeader :type="type">
      <template v-slot:breadcrumbs>
        <div class="back-button" @click="gotoPrevStep()">
          <img src="/img/icons/back_arrow.png" />
        </div>
        <Breadcrumb :items="breadcrumbs" />
      </template>

      <template v-slot:dropdown-buttons>
        <template v-if="type === 'inspector'">
          <b-dropdown-item aria-role="listitem">
            <b-button class="button" @click="onShowManageMTE">{{
              $t(`${TRANSLATION_ROOT}.manageMte`)
            }}</b-button>
          </b-dropdown-item>

          <b-dropdown-item aria-role="listitem">
            <b-button class="button" @click="onShowRepairAssessment">{{
              $t(`${TRANSLATION_ROOT}.repairAssessment`)
            }}</b-button>
          </b-dropdown-item>

          <b-dropdown-item aria-role="listitem">
            <b-upload
              v-model="fileAccuScan"
              class="button"
              v-if="taskType === 'diagram'"
            >
              <span class="flex">
                <b-icon class="file-icon" icon="gauge"></b-icon>
                <span>{{ $t(`${TRANSLATION_ROOT}.importAccuScan`) }}</span>
              </span>
            </b-upload>
          </b-dropdown-item>

          <b-dropdown-item aria-role="listitem">
            <b-upload
              v-model="fileLaserScan"
              class="button"
              v-if="taskType === 'diagram'"
            >
              <span class="flex">
                <img class="tool-image-icon" src="/img/acc_scan.png" />
                <span>{{ $t(`${TRANSLATION_ROOT}.importLaserscan`) }}</span>
              </span>
            </b-upload>
          </b-dropdown-item>
        </template>
      </template>

      <template v-slot:buttons>
        <template v-if="type === 'inspector'">
          <!-- <b-checkbox-button
            v-if="currentTask.requiredSigning"
            class="border-left"
            v-model="checkboxLeadman"
            type="is-success"
          >
            <b-icon icon="check"></b-icon>
            <span>Leadman</span>
          </b-checkbox-button> -->

          <b-button
            :disabled="isSignoffDisabled"
            class="button"
            type="is-info"
            @click="onSave"
            >{{ $t(`${TRANSLATION_ROOT}.signOff`) }}</b-button
          >
        </template>

        <template v-else>
          <b-checkbox v-model="requiredSigning"
            >Require leadman signing</b-checkbox
          >

          <b-button
            class="border-left button"
            type="is-danger"
            @click="onDelete"
            >{{ $t(`${TRANSLATION_ROOT}.delete`) }}</b-button
          >

          <b-button class="button" @click="onCancel">{{
            $t(`${TRANSLATION_ROOT}.back`)
          }}</b-button>

          <b-button class="button" type="is-info" @click="onSave">{{
            $t(`${TRANSLATION_ROOT}.save`)
          }}</b-button>
        </template>
      </template>
    </TaskHeader>
    <div v-if="!isLoading">
      <slot></slot>
    </div>
    <div v-else>
      <div
        v-if="taskType !== 'diagram' && taskQuestions.length === 0"
        class="instructions-skeleton"
      >
        <b-skeleton
          v-for="index in 8"
          :key="index"
          width="100%"
          height="70px"
          :animated="true"
        ></b-skeleton>
      </div>
      <div
        v-if="taskType === 'diagram' && taskBubbles.length === 0"
        class="diagram-skeleton"
      >
        <div style="width: 10%; height: 100%">
          <b-skeleton width="100%" height="87vh" :animated="true"></b-skeleton>
        </div>
        <div style="width: 74%; height: 100%">
          <b-skeleton width="100%" height="87vh" :animated="true"></b-skeleton>
        </div>
        <div style="width: 16%; height: 100%">
          <b-skeleton width="100%" height="87vh" :animated="true"></b-skeleton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as XLSX from 'xlsx';
import { GET_CURRENT_JOB } from '../../store/jobs/getters/getterTypes';
import {
  GET_CURRENT_FOLDER,
  GET_CURRENT_TASK,
} from '../../store/tasks/getters/getterTypes';
import { GET_TASK_BUBBLES } from '../../store/bubbles/getters/getterTypes';
import { GET_TASK_QUESTIONS } from '../../store/questions/getters/getterTypes';
import { GET_LOADING_STATE } from '../../store/loading/getters/getterTypes';

import {
  DELETE_TASK,
  SIGN_LEADMAN,
} from '../../store/tasks/actions/actionTypes';

import TaskHeader from './TaskHeader';
import Breadcrumb from './Breadcrumb';
import FoldersManagerMixin from '../../mixins/FoldersManagerMixin';
import {
  WEBSOCKET_EVENT_SUBSCRIBE_TO_ACTION,
  WEBSOCKET_EVENT_UNSUBSCRIBE_FROM_ACTION,
} from '../../store/websocket/actions/actionTypes';
import { SET_CURRENT_TASK_WS_CONNECTIONS } from '../../store/tasks/mutations/mutationTypes';

const TRANSLATION_ROOT = 'task_view';

export default {
  components: { TaskHeader, Breadcrumb },
  mixins: [FoldersManagerMixin],
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      TRANSLATION_ROOT,
      checkboxLeadman: false,
      requiredSigning: false,
      accuScan: null,
      fileAccuScan: null,
      fileLaserScan: null,
    };
  },
  computed: {
    isLeadman() {
      return this.$store.getters['auth/isLeadman'];
    },
    isInspector() {
      return this.$route.name === 'inspector-template';
    },
    taskQuestions() {
      return this.$store.getters[GET_TASK_QUESTIONS];
    },
    taskBubbles() {
      return this.$store.getters[GET_TASK_BUBBLES];
    },
    isLoading() {
      return this.$store.getters[GET_LOADING_STATE];
    },
    currentJob() {
      return this.$store.getters[GET_CURRENT_JOB];
    },
    currentTask() {
      return this.$store.getters[GET_CURRENT_TASK];
    },
    user() {
      return this.$store.state.currentUser;
    },
    currentFolder() {
      return this.$store.getters[GET_CURRENT_FOLDER];
    },
    taskCompleted() {
      return parseInt(this.currentTask.workStatusCode, 10) === 2;
    },
    breadcrumbs() {
      const breadcrumbs = [
        {
          label: this.currentJob.title,
          to: `/view/${this.type}/${this.currentJob._id}`,
          subLabels: [
            this.currentJob.frameType ? this.currentJob.frameType[0].text : '',
            ...this.computeTypeSubLevels(),
          ],
        },
      ];

      if (this.currentTask.folderId && this.currentFolder.taskName) {
        breadcrumbs.push({
          label: this.currentFolder.taskName,
          to: `/view/${this.type}/${this.currentJob._id}/folder/${this.currentTask.folderId}`,
        });
      }

      breadcrumbs.push({
        label: this.currentTask.taskName,
        to: `/view/${this.type}/${this.currentJob._id}/${this.currentTask._id}`,
      });
      return breadcrumbs;
    },
    taskType() {
      return this.currentTask?.taskType || null;
    },
    isSignoffDisabled() {
      const { requiredSigning } = this.currentTask;
      if (!requiredSigning) return false;
      const rolesToSign = this.user.roles.filter((value) =>
        ['publisher', 'editor', 'admin'].includes(value)
      );
      if (rolesToSign.length > 0) return false;
      return true;
    },
  },
  watch: {
    fileAccuScan(newVal) {
      if (!newVal) return;
      this.processCSVFile(
        newVal,
        'accuScanImport',
        () => (this.fileAccuScan = null)
      );
    },
    fileLaserScan(newVal) {
      if (!newVal) return;
      this.processCSVFile(
        newVal,
        'laserScanImport',
        () => (this.fileLaserScan = null)
      );
    },
    async requiredSigning(newValue) {
      this.currentTask.requiredSigning = newValue;
      await this.$store.dispatch('updateTask', this.currentTask);
    },

    async checkboxLeadman(newValue) {
      await this.$store.dispatch(SIGN_LEADMAN, {
        isSign: newValue,
        id: this.currentTask._id,
      });
    },
  },
  async created() {
    this.requiredSigning = this.currentTask.requiredSigning
      ? this.currentTask.requiredSigning
      : false;

    this.checkboxLeadman = this.currentTask.workStatusCode === '2';

    const wsMappings = {
      taskJoined: this.updateTaskConnections,
      taskLeft: this.updateTaskConnections,
    };

    Object.keys(wsMappings).forEach(async (key) => {
      await this.$store.dispatch(WEBSOCKET_EVENT_SUBSCRIBE_TO_ACTION, {
        action: key,
        callback: wsMappings[key],
      });
    });
  },

  beforeDestroy() {
    const wsMappings = {
      taskJoined: this.wsTaskJoined,
      taskLeft: this.updateTaskConnections,
    };

    Object.keys(wsMappings).forEach(async (key) => {
      await this.$store.dispatch(WEBSOCKET_EVENT_UNSUBSCRIBE_FROM_ACTION, {
        action: key,
        callback: wsMappings[key],
      });
    });

    window.removeEventListener('resize', this.handleResize);
  },

  methods: {
    updateTaskConnections(data) {
      this.$store.commit(SET_CURRENT_TASK_WS_CONNECTIONS, data);
    },
    processCSVFile(file, eventEmit, callbackOnSuccess) {
      if (file.type !== 'text/csv' && !file.name.endsWith('.csv')) {
        this.$buefy.snackbar.open({
          message: 'Please upload only .csv files',
          type: 'is-danger',
          position: 'is-top',
          duration: 5000,
        });
        return; // Stop processing any further
      }

      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });

        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const tableData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        this.$emit(eventEmit, tableData);
        callbackOnSuccess();
      };

      reader.onerror = (err) => {
        console.error('Error reading file:', err);
      };

      reader.readAsArrayBuffer(file);
    },

    onSave() {
      this.$emit('onSave', this.currentTask, this.onCancel);
    },
    onSignOff() {
      this.$emit('onSignOff');
    },
    gotoPrevStep() {
      const { id } = this.$route.params;
      const routeName = `${this.type}-tasks`; // e.g: 'editor-tasks'
      const { token } = this.$route.query || {};
      if (this.currentTask.folderId && !this.currentFolder.folderId) {
        const path = `/view/${this.type}/${this.currentJob._id}/folder/${this.currentTask.folderId}`;
        const query = token ? { token } : undefined;
        return this.$router.push({ path, query });
      }

      if (this.currentFolder.folderId) {
        const path = `/view/${this.type}/${id}/folder/${this.currentFolder.folderId}/${this.currentFolder._id}`;
        const query = token ? { token } : undefined;
        return this.$router.push({ path, query });
      }

      const params = { id };
      const query = token ? { token } : undefined;
      this.$router.push({ name: routeName, params, query });
    },

    onDelete() {
      this.$buefy.dialog.confirm({
        title: 'Deleting task',
        message: this.$t(`${TRANSLATION_ROOT}.deleteConfirmation`, {
          taskTitle: this.currentTask.title,
        }),
        confirmText: this.$t(`${TRANSLATION_ROOT}.deleteTask`),
        cancelText: this.$t(`${TRANSLATION_ROOT}.cancel`),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$store.dispatch(DELETE_TASK, this.currentTask._id);
          this.onCancel();
        },
      });
    },
    onCancel() {
      if (this.currentTask.folderId) {
        this.$router.push(
          `/view/${this.type}/${this.currentJob._id}/folder/${this.currentTask.folderId}`
        );
        return;
      }
      this.$router.push(`/view/${this.type}/${this.currentJob._id}`);
    },
    onShowManageMTE() {
      this.$emit('onShowManageMTE');
    },
    onShowRepairAssessment() {
      this.$emit('onShowRepairAssessment');
    },
    hideRepairAssessment() {
      this.repairAssessmentVisible = false;
    },
    computeTypeSubLevels() {
      if (this.type === 'editor') {
        return [
          this.currentJob.documentNumber,
          this.currentJob.revision,
          this.currentJob.documentNumber,
        ];
      }

      return [
        this.currentJob.projectInfoText,
        this.currentJob.customerInfoText,
        this.currentJob.workOrderNumber,
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.b-tooltips {
  .b-tooltip:not(:last-child) {
    margin-right: 0.5em;
  }
  .b-tooltip {
    margin-bottom: 0.5em;
  }
}
.diagram-space {
  widows: 80%;
}

.flex {
  display: flex;
  align-items: center;
}

.tool-image-icon {
  height: 24px;
  width: 24px;
  margin-left: calc(-0.375em - 1px);
  margin-right: 0.1875em;
}

.diagram-skeleton {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 10px;
}

.instructions-skeleton {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.back-button {
  display: inline-flex;
  width: 80px;
  height: 100%;
  cursor: pointer;
  img {
    width: 25px;
    margin: auto;
    &:hover {
      filter: brightness(0%);
    }
  }
}

.dropdown-import {
  margin-top: 14px;
}
</style>
