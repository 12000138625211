import getters from './getters';
import mutations from './mutations';

const diagramModule = {
  namespaced: true,
  state() {
    return {
      keypadNextButtonTableBubble: false,
      nextTableBubbleCellIndex: null,
    };
  },
  getters,
  mutations,
};

export default diagramModule;
