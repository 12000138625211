<template>
  <div class="spinner-container">
    <div class="spinner-line spinner-line-1"></div>
    <div class="spinner-line spinner-line-2"></div>
    <div class="spinner-line spinner-line-3"></div>
  </div>
</template>

<script>
export default {
  name: 'SpinningWheel',
};
</script>

<style scoped>
.spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner-line {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 4px solid transparent;
  border-radius: 50%;
}

.spinner-line-1 {
  border-top-color: #5b3ea5; /* Red */
  animation: spin-right 1.2s linear infinite;
}

.spinner-line-2 {
  border-top-color: #6c4fbf; /* Orange */
  animation: spin-left 1.5s linear infinite;
}

.spinner-line-3 {
  border-top-color: #a53e7f; /* Green */
  animation: spin-right 1.8s linear infinite;
}

/* Clockwise Rotation */
@keyframes spin-right {
  0% {
    transform: rotate(0deg) scale(1);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}

/* Counterclockwise Rotation */
@keyframes spin-left {
  0% {
    transform: rotate(0deg) scale(1);
  }
  100% {
    transform: rotate(-360deg) scale(1);
  }
}
</style>
